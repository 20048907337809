/* Slick slider styles */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	margin-bottom: 100px;

  opacity: 0;
  transition: .2s ease all;

  &.single-slide {
    margin-bottom: 40px;
  }

  &.slick-initialized {
    opacity: 1;
  }

	.slick-track,
	.slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	@include media-breakpoint-down(sm) {
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: 70px;
	}

	iframe {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-track {
	@extend .flex;
	position: relative;
	left: 0;
	top: 0;

	// display: block;

	// &:before,
	// &:after {
	// 	content: "";
	// 	display: table;
	// }

	// &:after {
	// 	clear: both;
	// }

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	min-height: 1px;
	display: none;
	position: relative;

	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		@extend .flex;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			display: block;
		}
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-arrow {
	@extend .flex-center;
	@extend .flex-middle;
	// width: 63px;
	// height: 103px;
	width: 17px;
	top: 50%;
	position: absolute;
	z-index: 99;
	// color: $black;
	// font-size: 64px;
	// line-height: 1;
	text-decoration: none;
	transform: translateY(-50%);
	// background: rgba($white, 0.7);

	&.slick-prev {
		left: -56px;
	}

	&.slick-next {
		right: -56px;
	}

	&:hover {
		text-decoration: none;
		// background: $white;
		opacity: .7;
	}
}

.slick-dots {
	@extend %listreset;
	@extend .flex-center;
	font-size: 0;
	line-height: 0;
	// padding-top: 25px;
	padding-top: 20px;
	color: transparent;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;

	li {
		margin: 0 10px 15px 9px;

		&.slick-active {
			button {
				// background-color: rgba($black, 0.5);
				background-color: #fff;
			}
		}
	}

	button {
		@include animate(background);
		background: $pink;
		border: none;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		display: block;
		padding: 0;

		&:hover {
			// background-color: rgba($black, 0.5);
			background-color: #fff;
		}
	}
}
