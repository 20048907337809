.story-block {
	padding: 59px 0 0;

	@include media-breakpoint-down(md) {
		margin: 62px 0 0;
		padding: 30px 0 0; 
	}

	@include media-breakpoint-down(sm) {
		padding: 35px 0 0;
		margin: 0;
	}

	.heading-holder {
		overflow: hidden;
		margin: 0 0 18px;

		@include media-breakpoint-down(md) {
			margin: 0 0 25px;
		}

		@include media-breakpoint-down(sm) {
			margin: 0 0 14px;
		}

		.text {
			display: block;
			font-size: 20px;
			margin: 0 0 2px;

			@include media-breakpoint-down(sm) {
				font-size: 14px;
				margin: 0 0 8px;
			}
		}
		
		.link-wrap {
			padding: 0 25px;
			display: inline-block;
			vertical-align: top;
			position: relative;
			font-size: 14px;
			font-weight: 700;

			@include media-breakpoint-down(sm) {
				font-size: 12px;
				padding: 0;
			}

			a {
				display: inline-block;
				vertical-align: top;
				color: #be0042;

				&:hover {
					// text-decoration: none;
				}
			}

			&:after,
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				background:#a19b9b ;
				height: 1px;

				@include media-breakpoint-down(sm) {
					content: none;
				}
			}

			&:after {
				left: -999px;
				right: 100%;
			}

			&:before {
				right: -999px;
				left: 100%;
			}
		}
	}

	.story-list {
		@extend %listreset;
		display: flex;
		flex-wrap:wrap;
		justify-content: space-between;

		@include media-breakpoint-down(md) {
			max-width: 496px;
			margin: 0 auto;
		}


		li {
			width: 50%;
			margin: 0 0 20px;
			padding: 0 20px;

			@include media-breakpoint-down(sm) {
				padding: 0 5px;
			}

			&:nth-child(2n) {
				.img-wrap {
					order:1;

					@include media-breakpoint-down(md) {
						order:0;
					}
				}

				.text-wrap {
					order:0;

					@include media-breakpoint-down(md) {
						order:1;
					}
				}
			}
		}

		.post-wrap {
			max-width: 400px;
			width: 100%;
		}

		a {
			display: flex;
			flex-wrap:wrap;
			align-items: center;

			&:hover {
				// text-decoration: none;
				color: #443738;
			}
		}
		
		.img-wrap {
			flex-basis:110px;
			max-width: 110px;
			height: 83px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			border-radius: 2px;
			overflow: hidden;

			@include media-breakpoint-down(md) {
				width: 100%;
				flex-basis: auto;
				max-width: 100%;
				height: 154px;
			}

			@include media-breakpoint-down(sm) {
				height: 96px;
			}
		}

		.text-wrap {
			flex:1;
			padding: 0 20px;
			font-size: 18px;
			line-height: 1.5;
			font-weight: 700;

			@include media-breakpoint-down(md) {
				text-align: center;
				padding: 17px 0;
			}

			@include media-breakpoint-down(sm) {
				font-size: 16px;
				padding: 12px 0;
			}
		}

		.category {
			font-size: 12px;
			font-weight: 700;
			color: #915040;
		}

		.category,
		.title {
			display: block;
		}
	}
}

.category-block {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin: 0 0 30px;

	@include media-breakpoint-down(md) {
		display: block;
		text-align: center;
	}

	.title {
		display: inline-block;
		vertical-align: top;
		padding-right: 10px;
		margin: 0 0 10px;
		text-transform: uppercase;
		font-size: 15px;
		@include media-breakpoint-down(md) {
			padding-right: 0;
		}
	}

	.list-items {
		@extend %listreset;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		@include media-breakpoint-down(md) {
			justify-content: center;
		}

		li {
			font-size: 15px;
			padding: 0 5px;
			margin: 0 0 10px;

			&:last-child {
				@include media-breakpoint-down(md) {
					width: 100%;
				}
			}
		}

		a {
			&.opener {
				font-weight: 400;
				color: #443738;

				&:hover {
					// text-decoration: none;
					// color: #be0042;
					color: #dd907c;
				}

				span {
					padding-right: 5px;
					margin: 0 0 4px;
					display: inline-block;
					vertical-align: middle;
				}
			}
		}

		.btn {
			margin-bottom: 0;
			font-size: 15px;
		}
	}
}

.more {
	color: #be0042;
	font-size: 22px;
	line-height: 1.2;
	display: table;
	margin-left: auto;
	margin-right: auto;

	&:hover {
		// text-decoration: none;
	}
}

.related-block {
	margin: 0 auto;
	padding: 50px 0;

	@include media-breakpoint-down(md) {
		padding: 50px 0 35px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.container {
		@include media-breakpoint-down(md) {
			max-width: 420px;
		}
	}


	&.related-block--stories {
		background-color: #f8f8f8;
		padding-top: 97px;
		padding-bottom: 82px;

		@include media-breakpoint-down(sm) {
			padding-top: 81px;
			padding-bottom: 31px;
		}

		.category {
			text-transform: uppercase;
		}
	}

	h2 {
		overflow: hidden;
		text-align: center;
		text-transform: uppercase;
		margin: 0 0 28px;
		font-size: 18px;
		line-height: 1.2;
		color: #443738;
		font-weight: 400;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			margin-bottom: 30px;
		}

		.style {
			display: inline-block;
			vertical-align: top;
			position: relative;
			padding: 0 10px;

			// @include media-breakpoint-down(sm) {
			// 	padding: 0 15px;
			// }

			&:after,
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				height: 1px;
				margin-top: -2px;
				background: #d9d7d7;

				@include media-breakpoint-down(sm) {
					margin-top: -1px;
				}
			}

			&:after {
				left: 100%;
				right: -999px;
			}

			&:before {
				right: 100%;
				left: -999px;
			}
		}
	}

	.post-wrap {
		@include animate(box-shadow);
		background: $white;
		margin: 0 0 40px;
		color: #443738;
		display: block;
		text-decoration: none;
		box-shadow: 0 10px 35px rgba(0,0,0,0);

		&:hover {
			box-shadow: 0 10px 35px rgba(0,0,0,.1);

			.bg-stretch {
				transform: scale(1.1);
			}

			h3 {
				color: #915040;
				text-decoration: underline;
			}
		}
	}

	.img-hold {
		height: 300px;
		display: block;
		overflow: hidden;
		position: relative;

		@include media-breakpoint-down(md) {
			height: 245px;
		}
	}

	.bg-stretch {
		@include animate(transform, 1.5s,cubic-bezier(.22,.89,.57,1));
		position: absolute;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.text-hold {
		padding: 36px 30px 33px;

		@include media-breakpoint-down(sm) {
			padding-top: 28px;
			padding-bottom: 25px;
		}
	}

	.category {
		font-size: 12px;
		letter-spacing: 1.2px;	
		font-weight: 700;
		display: block;
		margin: 0 0 8px;
		color: #915040;
		text-transform: uppercase;

		span {
			text-transform: uppercase;
		}
	}

	h3 {
		@include animate(color);
		font-size: 22px;
		color: #443738;
		// min-height: 67px;

		min-height: 124px;

		@include media-breakpoint-down(md) {
			min-height: 50px;
			font-size: 20px;
			line-height: 1.4;
		}
	}

	time {
		font-size: 12px;
		font-weight: 400;
		text-transform: uppercase;
	}
}