h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	
}

h1,
.h1 {
	font-weight: 400;

	@include media-breakpoint-down(sm) {
		font-size: 35px;
	}
}
.h1-xl {
  font-size: 90px;

  @include media-breakpoint-down(sm) {
		font-size: 70px;
	}
}

h2,
.h2 {
	@include media-breakpoint-down(sm) {
		font-size: 30px;
	}
}

h3,
.h3 {
	@include media-breakpoint-down(sm) {
		font-size: 23px;
	}
}

h4,
.h4 {
	@include media-breakpoint-down(sm) {
		font-size: 18px;
	}
}

a {
	@include animate(opacity visibility color border background box-shadow);
}

p {
  @include media-breakpoint-up(md) {
    line-height: 1.9;
  }
}

.p {
  font-size: $font-size-base;
  font-weight: normal;
  line-height: $line-height-base;
}
