.caps {
	font-size: 40px;

		@include media-breakpoint-down(sm) {
			font-size: 30px;
		}
}

.hero--no-logo {
	padding-bottom: 100px;
}
.hero--no-logo.hero--sm-padding {
	padding-bottom: 100px;
}

.covid.container-fluid {
	max-width: 912px;
	padding-left: 40px;
	padding-right: 40px;
	margin-bottom: 59px;

	@media (max-width: 1023px) {
		margin-bottom: 65px;
	}
	@media (max-width: 767px) {
		margin-bottom: 70px;
		padding-left: 20px;
	padding-right: 20px;
	}

	p {
		line-height: 1.62;
		margin-bottom: 28px;

		@media (max-width: 767px) {
			margin-bottom: 32px
		}
	}

	ul {
		margin-bottom: 36px;

		@media (max-width: 767px) {
			margin-bottom: 32px
		}

	}

}

.covid-page {
	background-color: #f8f8f8;
	position: relative;
	padding-top: 75px;
	padding-bottom: 1px;

	@include media-breakpoint-down(sm) {
		padding-top: 55px;
	}

	&:before {
		position: absolute;
		content: '';
		height: 300px;
		top: 0;
		left: -50px;
		right: -50px;
		pointer-events: none;
		box-shadow: inset 0 5px 5px 2px rgba(0, 0, 0, .01);
	}

	.h1 {
		color: #443738;
		font-weight: 700;
		font-size: 32px;
		margin-bottom: 46px;
		line-height: 1.275;

		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
			font-size: 26px;
		}
	}

	.covid__content {
		margin-bottom: 93px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 43px;
		}
	}

	ul {
		@extend %listreset;
		margin-right: 7%;

		@include media-breakpoint-down(sm) {
			margin-right: 0;
		}

		li {
			padding-left: 31px;
			position: relative;
			margin-bottom: 0.08333em;

			@include media-breakpoint-down(sm) {
				padding-left: 22px;
			}

			&:before {
				position: absolute;
				content: '•';
				top: 1px;
				left: 18px;

				@include media-breakpoint-down(sm) {
					left: 5px;
				}
			}
		}
	}
}

.covid_image {
	margin-top: 85px;
	margin-bottom: 95px;
	display: block;

	@media (max-width: 1023px) {
		display: none;
	}
}

[class^="theme--"] {
	position: relative;
	margin-top: 70px;
	margin-bottom: 28px;
}
[class^="theme--"]:before {
	content: "";
	position: absolute;
	top: -15px;
	width: 50px;
	border-top-width: 4px;
	border-top-style: solid;
}
.theme--keep:before {
	border-top-color: #cd0954;
}
.theme--deliver:before {
	border-top-color: #3cc7f2;
}
.theme--provide:before {
	border-top-color: #7ac142;
}
.featured-content--wide {
	padding: 5px 10px 10px 10px;
	margin-top: 54px;
}
.featured-content--wide .featured-content__inner {
	max-width: 1040px;
}
.featured-content--wide .featured-content__inner h4 {
	letter-spacing: 1px;
}

.btn-primary:not([disabled]):not(.disabled):focus,
.btn-primary:not([disabled]):not(.disabled):active {
	background: #915040;
	border-color: #915040;
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
}


.story-page h3 {
	font-size: 30px;
}
.story-page img {
	display: inline-block;
	margin-bottom: 50px;
}

.page-navigation {
	.sections {
		span {
			text-transform: uppercase;
			font-weight: bold;
		}
	}
}

.nav-section {
	position: relative;
	transition: .2s ease all;

	@media (max-width: 575px) {
		text-align: left;
		padding-left: 15px;
		padding-right: 15px;

		&:before {
			content: "";
			margin-bottom: 15px;
			display: block;
			width: 100%;
			border-top: 2px solid #ddd;
		}

		&:last-child {
			&:after {
				content: "";
				margin-top: 15px;
				display: block;
				width: 100%;
				border-top: 2px solid #ddd;
			}
		}
	}

	

	p {
		line-height: 1.5;
		margin-bottom: 10px !important;

		@media (max-width: 575px) {
			padding-right: 120px;
		}
		@media (max-width: 420px) {
			padding-right: 60px;
		}
	}

	.btn-jump {
		@media (max-width: 575px) {
			position: absolute;
			right: 35px;
			top: 50%;
			transform: translateY(-50%);
			width: 24px;
			height: 24px;
			background-size: 12px;
		}
	}

	&:hover {
		// text-decoration: none;
		color: $brown;

		.btn-jump {
			background-color: $brown;
		}
	}
}

.section--keep {
	color: $pink;

	.btn-jump {
		background-color: $pink;
	}
}
.section--deliver {
	color: $color-blue;

	.btn-jump {
		background-color: $color-blue;
	}
}
.section--provide {
	color: #78B52E;

	.btn-jump {
		background-color: #78B52E;
	}
}

.btn-jump {
	display: inline-block;
	border-radius: 100px;
	width: 38px;
	height: 38px;
	background-position: center center;
	background-size: 16px;
	background-repeat: no-repeat;
	transition: .2s ease all;

	&--down {
		background-image: url('/img/arrow-down-white.png');
	}
	&--up {
		background-color: rgba(217,215,215,1);
		background-image: url('/img/arrow-up-brown.png');

		&:hover {
			background-color: rgba(240,239,239,1);
		}
	}

}

.story-section {
	margin-bottom: 40px;

	article.story {
		padding-bottom: 50px;
		margin-bottom: 55px;
		border-bottom: 1px solid #C7C7C7;

		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
		}
	}
}

.section-banner {
	color: #fff;
	padding: 15px;
	font-size: 1.125rem;
	line-height: 1.2;
	-webkit-font-smoothing: antialiased;
	margin-bottom: 50px;

	span {
		text-transform: uppercase;
		font-weight: bold;
	}

	&--keep {
		background-color: $pink;
	}
	&--deliver {
		background-color: $color-blue;
	}
	&--provide {
		background-color: #78B52E;
	}
}