/* brand styles */
.brand-holder {
	padding-bottom: 88px;

	@include media-breakpoint-down(sm) {
		padding-bottom: 80px;
	}
}

.slider {
	margin-bottom: 77px;
	padding: 82px 60px 70px;
	background-color: #443738;

	// &--pulled {
	//   margin-top: -85px;

	@include media-breakpoint-down(sm) {
		padding: 35px 20px 40px;
	}
	@include media-breakpoint-down(xs) {
		padding: 35px 20px 40px;
	}
	// }

	.slick-slider {
		margin: 0 auto;
		max-width: 980px;
		margin-bottom: 20px;

		.slick-arrow {
			&.slick-prev {
				@include media-breakpoint-down(lg) {
					left: -42px;
				}
			}

			&.slick-next {
				@include media-breakpoint-down(lg) {
					right: -42px;
				}
			}
		}
	}
}

.brands-section {
	padding-top: 20px;
	position: relative;

	+ .brands-section {
		padding-top: 104px;
		margin-top: 57px;

		@include media-breakpoint-down(xs) {
			padding-top: 60px;
		}

		&:before {
			content: '';
			background: linear-gradient(to right, rgba(205,9,84,1) 0%,rgba(196,21,94,1) 20%,rgba(60,199,242,1) 20%,rgba(60,199,242,1) 40%,rgba(145,80,64,1) 40%,rgba(145,80,64,1) 60%,rgba(122,193,66,1) 60%,rgba(119,184,65,1) 80%,rgba(68,55,56,1) 80%,rgba(68,55,56,1) 100%);
			width: 440px;
			height: 3px;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);

			@include media-breakpoint-down(sm) {
				width: 193px;
			}
		}
	}

	.col-holder {
		padding: 0 25px;
		margin-bottom: 9px;
		width: 20%;

		@include media-breakpoint-down(sm) {
			width: 50%;
			text-align: center;
		}

		a {
			display: block;
			transition: .3s ease opacity;
			opacity: 1;
		}

		&:hover {
			a {
				opacity: .6;
			}
		}
	}

	&__head {
		text-align: center;
		margin-bottom: 21px;
		padding: 0 110px;

		@include media-breakpoint-down(lg) {
			padding: 0;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 48px;
		}
	}

	h2, .h2 {
		// margin-bottom: 37px;

		margin-bottom: 33px;
	}

	.row {
		&.flex-wrap {
			margin: 0;
		}
	}
}
