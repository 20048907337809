/*
 * set default text color
 * set default font size, line height and font family
 * set default background
 */
body {
	min-width: 320px;
	// background-color: #f8f8f8;

	@include media-breakpoint-down(sm) {
		font-size: 18px;
		line-height: 1.78;
	}

	&.nav-active {
		@include media-breakpoint-down(md) {
			overflow: hidden;
		}
	}
}

// flex
.flex {
	display: flex;
	flex-wrap: wrap;

	&-top {
		@extend .flex;
		align-items: flex-start;
	}

	&-middle {
		@extend .flex;
		align-items: center;
	}

	&-bottom {
		@extend .flex;
		align-items: flex-end;
	}

	&-justify {
		@extend .flex;
		justify-content: space-between;
	}

	&-center {
		@extend .flex;
		justify-content: center;
	}

	&-end {
		@extend .flex;
		justify-content: flex-end;
	}
}
