.ethics {
	background-color: #f8f8f8;
	position: relative;
	padding-top: 75px;
	padding-bottom: 1px;

	@include media-breakpoint-down(sm) {
		padding-top: 55px;
	}

	.container {
		max-width: 912px;
		padding-left: 40px;
		padding-right: 40px;
		margin-bottom: 45px;

		@include media-breakpoint-down(md) {
			margin-bottom: 65px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 35px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&:before {
		position: absolute;
		content: '';
		height: 300px;
		top: 0;
		left: -50px;
		right: -50px;
		box-shadow: inset 0 5px 5px 2px rgba(0, 0, 0, .01);
	}

	h2 {
		color: #443738;
		font-weight: 700;
		font-size: 32px;
		margin-bottom: 43px;
		line-height: 1.275;

		@include media-breakpoint-down(sm) {
			margin-bottom: 30px;
			font-size: 26px;
		}
	}

	.h2 {
		font-size: 20px;
		font-weight: 700;
		line-height: 1.8;
		margin-bottom: 24px;
	}

	h3 {
		font-size: 18px;
		font-weight: 700;
		line-height: 1.62;
		margin-bottom: 27px;
	}

	&__content {
		margin-right: -4px;
		margin-bottom: 59px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 43px;
			margin-right: 0;
		}

		p {
			font-size: 18px;
			line-height: 1.62;
			margin-bottom: 28px;
		}
	}

	ol {
		@extend %listreset;
		counter-reset: item;
		padding-top: 7px;

		li {
			margin-left: 40px;
			position: relative;
			margin-bottom: 33px;
			counter-increment: item;

			@include media-breakpoint-down(sm) {
				margin-left: 32px;
				margin-bottom: 22px;
			}

			&:before {
				position: absolute;
				top: 4px;
				right: 100%;
				content: counter(item) '.';
				color: #443738;
				font-size: 18px;
				font-weight: 700;
				line-height:  1.2;
				margin-right: 8px;
				min-width: 16px;
			}
		}
	}

	ul {
		@extend %listreset;
		margin-right: 7%;
		padding-top: 4px;
		padding-bottom: 2px;
		margin-bottom: 29px;

		@include media-breakpoint-down(sm) {
			margin-right: 0;
		}

		li {
			padding-left: 40px;
			position: relative;
			margin-bottom: 1px;

			@include media-breakpoint-down(sm) {
				padding-left: 32px;
			}

			&:before {
				position: absolute;
				content: '•';
				top: 0;
				left: 17px;

				@include media-breakpoint-down(sm) {
					left: 5px;
				}
			}
		}
	}
}