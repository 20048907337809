.leadership-wrapper {
	background-color: #f8f8f8;
	position: relative;
	padding-top: 71px;
	padding-bottom: 1px;
	padding-bottom: 35px;

	@include media-breakpoint-down(sm) {
		padding-top: 80px;
		padding-bottom: 33px;
	}

	&:before {
		position: absolute;
		content: '';
		height: 300px;
		top: 0;
		left: -50px;
		right: -50px;
		pointer-events: none;
		box-shadow: inset 0 5px 5px 2px rgba(0, 0, 0, .01);
	}
}

/* teams styles */
.team-area {
	margin-bottom: 96px;

	@include media-breakpoint-down(md) {
		margin-bottom: 45px;
	}

	@include media-breakpoint-down(sm) {
		padding: 12px 20px 0;
		margin-bottom: 43px;
	}

	@media only screen and (max-width: 450px) {
		padding: 12px 3px 0;
	}
}

.team {
	margin-bottom: 36px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 40px;
	}

	h2, .h2 {
		font-size: 32px;
		line-height: 1.2;
		margin-bottom: 46px;

		@include media-breakpoint-down(sm) {
			font-size: 21px;
			line-height: 1.2;
			margin-bottom: 33px;
		}
	}

	p {
		margin-bottom: 35px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 42px;
		}
	}

	h3, .h3 {
		margin-bottom: 5px;
		text-transform: capitalize;
		display: block;
		font-size: 18px;
		line-height: 1.4;

		@include media-breakpoint-down(sm) {
			margin-bottom: 4px;
		}

		a {
			color: #be0042;
			// text-decoration: none;
		}
	}

	.text {
		display: block;
		text-transform: uppercase;
		font-size: 16px;
		line-height: 2.25;
		margin-bottom: 42px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 43px;
		}
	}
}

/* modal styles */
.modal {
  background: rgba($black, 0.95);
}

.modal-dialog {
  margin-top: 154px;

  @include media-breakpoint-down(sm) {
    margin-top: 111px;
  }
}

.modal-content {
  background: none;
  border: none;
  color: $white;
  padding: 0 40px;

  @include media-breakpoint-down(sm) {
    padding: 0 28px;
  }

  @media only screen and (max-width: 450px) {
    padding: 0 12px;
  }

  ul {
    margin-bottom: 30px;
  }
}

.modal-header {
  border: none;
  padding: 0;
  margin-bottom: 39px;
  display: block;

  .modal-title {
    line-height: 1.2;
    display: block;
    margin: 0 0 3px;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }

  .sub {
    text-transform: uppercase;
    color: #7ac142;
    font-weight: 700;
    display: block;

    @include media-breakpoint-down(sm) {
      line-height: 1.67;
    }
  }
}

.modal-body {
  padding: 0;
  font-size: 20px;
  line-height: 1.9;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
    line-height: 1.78;
  }

  p {
    margin-bottom: 38px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 32px;
    }
  }

  h2.modal-impact-heading {
    margin-top: 40px;
  }

  a {
    color: $white;
    // text-decoration: underline;

    &:hover {
      color: $pink;
    }
  }
}

button.close {
  opacity: 1;
  background: $pink;
  border-radius: 0 0 34px 34px;
  text-align: center;
  width: 67px;
  height: 69px;
  text-indent: -9999px;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 200px;
  transition: .2s ease all;

  &:hover {
    background-color: $brown;
    opacity: 1;
  }

  @include media-breakpoint-down(lg) {
    right: 100px;
  }

  @include media-breakpoint-down(sm) {
    width: 58px;
    height: 61px;
    right: 45px;
  }

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 20px;
    background: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.country-selector {
  ul {
    li {
      a {
        &:hover {
          color: #dd907c;
          text-decoration: underline;
        }
      }
    }
    li.selected {
      a {
        border-bottom: 2px solid rgba(176,102,84,1);
        padding-bottom: 1px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}