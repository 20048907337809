.sticky-wrap-sidebar {
	@include media-breakpoint-down(md) {
		padding: 0;
	}

	&.fixed-position {
		padding: 150px 0;

		.sidebar {
			padding: 30px 0 50px;

			@include media-breakpoint-down(md) {
				padding: 0;
				position: static !important;
			}
		}
	}
}

span {
	&.sidebar{
		&__title {
			font-weight: 400;

			strong {
				font-weight: 700;
			}

			&--blue-border {
				margin-bottom: 45px;

				&:before {
					background: #3fc7f2;
				}
			}
		}
	}
}

// .career-holder {
// 	padding-bottom: 17px;

// 	.slick-slider {
// 		margin-bottom: 129px;
// 	}
// }

.career-wrapper {
	padding-bottom: 60px;

  @include media-breakpoint-up(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

	@include media-breakpoint-down(sm) {
		padding-top: 30px;
	}

	.sidebar {
		margin-top: 13px;

		@include media-breakpoint-down(sm) {
			margin-top: 0;
			padding-top: 36px;
			margin-bottom: 41px;
		}
	}

	.company__content {
		margin-bottom: 0;
	}

	.sidebar__title {
		@include media-breakpoint-down(sm) {
			font-size: 16px;
		}

		&:before {
			@include media-breakpoint-down(sm) {
				bottom: -9px;
			}
		}
	}
}

.sidebar {
	position: relative;
	margin-top: -4px;
	padding-bottom: 50px;

	@include media-breakpoint-down(md) {
		padding-bottom: 0;
		margin-bottom: 90px;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 121px;
	}
	@media only screen and (max-width: 450px) {
		padding: 0;
	}

	&__splash {
		position: absolute;
		margin-bottom: 21px;
		bottom: 100%;
		left: 30px;

		@include media-breakpoint-down(md) {
			display: none;
		}

		.fixed-position & {
			@include media-breakpoint-up(lg) {
				display: none;
			}
		}
	}

	&__title {
		color: $black;
		font-size: 18px;
		font-weight: 700;
		line-height: 1.2;
		position: relative;
		display: block;
		margin-bottom: 36px;

		&:before {
			left: 0;
			right: 0;
			bottom: -11px;
			content: '';
			height: 3px;
			position: absolute;
			background: #7ac142;
		}
	}

	&__more {
		@extend %listreset;

		@include media-breakpoint-down(sm) {
			margin-top: -2px;
		}

		li {
			font-weight: 700;
			font-size: 26px;
			line-height: 1.23;
			margin-bottom: 25px;

			@include media-breakpoint-down(sm) {
				font-size: 23px;
				line-height: 1.3043;
			}
		}

		a {
			// text-decoration: none;
		}
	}
}

.secondary-nav {
	@extend %listreset;
	line-height: 1.2;

	@include media-breakpoint-down(lg) {
		font-size: 20px;
		margin-right: -10px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 18px;
		line-height: 1.2;
	}

	li {
		padding-left: 20px;
		position: relative;
		margin-bottom: 23px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 18px;
		}

		&.active {
			font-weight: 700;
		}

		&:before {
			position: absolute;
			top: 7px;
			left: 0;
			content: "\e906";
			font: 10px/1 'icomoon';
			speak: none;
			font-weight: 700;
			text-shadow: 1px 0 0 $black;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				top: 5px;
			}

			@include media-breakpoint-down(sm) {
				top: 5px;
			}
		}
	}

	a {
		color: $black;
		// text-decoration: none;

		&:hover {
			color: $red;
		}
	}
}
