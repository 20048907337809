.hero {
	@extend .flex;
	padding-top: 115px;
	padding-bottom: 127px;
	position: relative;
	overflow: hidden;
	z-index: 0;
	min-height: 370px;

	@include media-breakpoint-down(sm) {
		padding-top: 39px;
		padding-bottom: 84px;
		margin-bottom: 0;
	}

	.container-fluid {
		display: flex;
		align-items: center;
	}

	&__logo {
		margin-bottom: 18px;
		transition: .2s ease all;
		max-width: 470px;

		@include media-breakpoint-down(md) {
			width: 400px;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 27px;
			width: 265px;
		}
	}

	&__wrap {
		max-width: 725px;

		@include media-breakpoint-down(md) {
			max-width: 550px;
		}

		@include media-breakpoint-down(sm) {
			padding: 0 20px;
		}

		@media only screen and (max-width: 450px) {
			padding: 0;
		}
	}

	&__text {
		padding-left: 124px;

		p,
		.p {
			font-size: 20px;
			line-height: 1.8;

			@include media-breakpoint-down(sm) {
				font-size: 1.25rem;
				line-height: 1.67;
			}
		}

		@include media-breakpoint-down(md) {
			padding: 0;
		}

		@include media-breakpoint-down(sm) {
			line-height: 1.67;
		}
	}

	&__splash {
		position: absolute;
		z-index: -1;
		max-width: 50%;
		bottom: 0;
		right: 0;

		@include media-breakpoint-down(sm) {
			width: 350px;
			max-width: 70%;
		}
	}

	&__sub-heading {
		color: #be0042;
		font-size: 12px;
		letter-spacing: 0.1em;
		line-height: 1.2;
		margin-bottom: 9px;
		display: block;
		text-transform: uppercase;

		@include media-breakpoint-down(sm) {
			margin-bottom: 11px;
		}

		a {
			&:hover {
				color: inherit;
			}
		}
	}

	&--no-paragraph,
	&--no-logo {
		text-align: center;
		padding-top: 93px;
		padding-bottom: 49px;
		min-height: 373px;

		@include media-breakpoint-down(lg) {
			padding-bottom: 63px;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 60px;
		}
		@include media-breakpoint-down(sm) {
			padding: 60px 21px 153px;
		}
		@media only screen and (max-width: 450px) {
			padding: 60px 0 153px;
		}

		p, .p {
			line-height: 1.7;

			@include media-breakpoint-down(md) {
				max-width: 560px;
				margin-left: auto;
				margin-right: auto;
			}
			@include media-breakpoint-down(sm) {
				margin-right: -4px;
				margin-left: 0;
			}
		}

		h1 {
			text-transform: uppercase;
			margin-bottom: 26px;
			line-height: 1.17391;

			@include media-breakpoint-down(sm) {
				line-height: 1.0571;
				margin-bottom: 34px;
			}
		}

		.hero__text {
			padding: 0;
			max-width: 800px;
			margin: 0 auto;

			@include media-breakpoint-down(lg) {
				max-width: 700px;
			}
		}

		.hero__splash {
			bottom: -50px;
			right: -30px;

			@include media-breakpoint-down(lg) {
				max-width: 18%;
				bottom: -28px;
			}
			@include media-breakpoint-down(md) {
				bottom: -40px;
				right: -18px;
				max-width: 157px;
			}

			&--left {
				right: auto;
				left: -80px;
				bottom: -30px;

				@include media-breakpoint-down(lg) {
					max-width: 33%;
				}
				@include media-breakpoint-down(md) {
					left: -52px;
					right: auto;
					bottom: -28px;
					max-width: 243px;
				}
			}
		}
	}

	&--no-paragraph {
		padding-top: 53px;
		min-height: 293px;

		@include media-breakpoint-down(lg) {
			padding-bottom: 63px;
		}
		@include media-breakpoint-down(md) {
			padding-bottom: 60px;
		}
		@include media-breakpoint-down(sm) {
			padding: 60px 21px 100px;
		}
		@media only screen and (max-width: 450px) {
			padding: 60px 0 100px;
		}
	}

	&__icon {
		width: 56px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 17px;

		@include media-breakpoint-down(sm) {
			width: 44px;
			margin-bottom: 25px;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&.hero--label,
	&.hero--icon {
		min-height: 405px;
		padding-top: 62px;

		@include media-breakpoint-down(lg) {
			min-height: 362px;
		}
		@include media-breakpoint-down(sm) {
			padding-top: 72px;
			padding-bottom: 181px;
		}

		.hero__text {
			max-width: 835px;

			@include media-breakpoint-down(lg) {
				max-width: 700px;
			}
			@include media-breakpoint-down(sm) {
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		h1 {
			@include media-breakpoint-down(sm) {
				margin-bottom: 29px;
			}
		}

		p {
			font-size: 18px;
			line-height: 1.62;
		}
	}

	&--bio {
		padding-top: 130px;
		padding-bottom: 195px;

		@include media-breakpoint-down(sm) {
			padding-top: 74px;
			padding-bottom: 218px;
		}

		.hero__sub-heading {
			margin-bottom: 20px;

			@include media-breakpoint-down(sm) {
				color: #eb3e80;
				margin-bottom: 9px;
			}
		}

		.text {
			display: block;
			font-size: 16px;
			line-height: 1.2;
			margin-bottom: 53px;
			text-transform: uppercase;

			@include media-breakpoint-down(sm) {
				font-size: 14px;
				margin-bottom: 48px;
				line-height: 1.2;
			}
		}

		.hero__img {
			width: 211px;
			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-down(sm) {
				width: 153px;
			}
			
			img {
				width: 100%;
				height: auto;
			}
		}

		h1 {
			font-size: 50px;
			font-weight: 700;
			line-height: 1.2;
			margin-bottom: 12px;
			text-transform: capitalize;

			@include media-breakpoint-down(sm) {
				font-size: 35px;
				line-height: 1.2;
				margin-bottom: 16px;
			}
		}

		.p, p {
			font-size: 18px;
			line-height: 1.62;
			text-align: left;
			margin-bottom: 28px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 27px;
			}
		}

		.hero__text {
			max-width: 838px;

			@include media-breakpoint-down(lg) {
				max-width: 700px;
			}

			@include media-breakpoint-down(sm) {
				padding-left: 4px;
				padding-right: 6px;
			}
		}

		.hero__splash {
			bottom: -77px;
			right: -78px;

			@include media-breakpoint-down(lg) {
				max-width: 30%;
			}
			@include media-breakpoint-down(md) {
				max-width: 28%;
				bottom: -47px;
				right: -18px;
			}

			@include media-breakpoint-down(sm) {
				max-width: 179px;
				bottom: -35px;
				right: -22px;
			}

			&--left {
				top: 78px;
				left: 36px;
				bottom: auto;
				right: auto;

				@include media-breakpoint-down(lg) {
					left: -84px;
					top: 28px;
					max-width: 38%;
				}
				@include media-breakpoint-down(md) {
					left: -84px;
					top: 28px;
				}
				@include media-breakpoint-down(sm) {
					left: -50px;
					top: -3px;
					max-width: 167px;
				}
			}
		}

		.hero__heading {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 36px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 37px;
			}
		}

		+ .leadership-wrapper {
			padding-bottom: 25px;

			@include media-breakpoint-down(sm) {
				padding-bottom: 33px;
			}

			.team-area {
				margin-bottom: 0;
			}
		}
	}

	&.hero--icon {
		padding-top: 87px;
		padding-bottom: 80px;

		@include media-breakpoint-down(sm) {
			padding-top: 37px;
			padding-bottom: 140px;
		}

		.hero__text {
			@include media-breakpoint-down(sm) {
				padding-left: 4px;
				padding-right: 4px;
			}
		}
	}
}