.news-archive {
	@extend %listreset;

	a {
		color: $black;
		display: block;

		&:hover {
			// text-decoration: none;
			color: $pink;
		}
	}

	time {
		font-size: 14px;
		line-height: 1.2;
		text-transform: uppercase;
		font-weight: 700;
		color: $pink;
		display: inline-block;
		margin-bottom: 21px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
	}

	.h2 {
		font-size: 30px;
		line-height: 1.2;

		@include media-breakpoint-down(sm) {
			font-size: 23px;
			line-height: 1.4;
		}
	}
}

.general-page {
	&__news {
		h1 {
			margin-bottom: 46px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 35px;
			}
		}
	}

	&__article {
		p {
			line-height: 2;

			@include media-breakpoint-down(sm) {
				line-height: 2.11;
			}
		}

		.h3 {
			display: block;
			font-weight: 400;
			margin-bottom: 50px;
			text-transform: uppercase;

			a {
				&:hover {
					// text-decoration: none;
				}
			}
		}

		h1 {
			text-transform: none;
			margin-bottom: 48px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 44px;
			}
		}

		time {
			font-size: 14px;
			font-weight: 700;
			display: block;
			margin: 0 0 47px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 43px;
			}
		}
	}
}

.news-archive {
	li {
		border-bottom: 1px solid #a19b9b;
		padding: 38px 0 23px;

		@include media-breakpoint-down(sm) {
			padding: 28px 0 13px;
		}

		&:first-child {
			border-top: 1px solid #a19b9b;
		}
	}
}

.recent {
	padding-top: 56px;

	.h3 {
		display: block;
		font-weight: 400;
		color: $black;
		text-transform: uppercase;
		margin-bottom: 35px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 22px;
		}
	}

	.news-archive {
		margin-bottom: 49px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 23px;
		}
	}

	time {
		margin-bottom: 22px;
		text-transform: uppercase;

		@include media-breakpoint-down(sm) {
			margin-bottom: 15px;
		}
	}

	.btn {
		padding: 13px 30px;

		@include media-breakpoint-down(sm) {
			padding: 14px 36px;
		}
	}
}

.share-hold {
	padding-top: 93px;
	padding-left: 77px;

	@include media-breakpoint-down(md) {
		padding-left: 0;
		padding-top: 0;
	}

	&.has-sticky {
		@include media-breakpoint-down(md) {
			position: relative !important;
			width: auto !important;
			top: inherit !important;
			left: inherit !important;
		}
	}

	.title {
		display: block;
		font-size: 16px;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	.social-networks {
		@extend %listreset;
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;

		li {
			padding: 0 5px;
		}

		a {
			color: $black;

			&:hover {
				color: $pink;
				text-decoration: none;
			}
		}

		.ico {
			font-size: 32px;
			line-height: 1;
		}
	}
}

.side-contact {
	padding-top: 95px;
	transition: .2s ease all;

	.fixed-position & {
		// padding-top: 50px;
	}

	a {
		@include media-breakpoint-down(lg) {
			font-size: 18px;
		}
	}
}