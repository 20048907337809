.feature-outer {
	// padding-top: 10px;
	padding-bottom: 5px;
}

.featured-content {
	padding: 0 10px 8px;
	position:relative;

	.img-container{
		position:absolute;
		//@media (max-width:767px) {
			top:0px;
			height: 100%;
			left:50%;
			transform: translate(-50%);
		//}

		img{
			//@media ( max-width:767px ){
				height: 100%;
				max-width: none;
			//}
		}
	}

	&--single {
		padding-top: 10px;
		padding-bottom: 0;
	}

	&--right {
		text-align: right;

		@include media-breakpoint-down(sm) {
			text-align: left;
		}

		.featured-content__inner {
			margin-left: auto;
		}
		.btn-holder {
			justify-content: flex-end;

			@include media-breakpoint-down(sm) {
				justify-content: flex-start;
			}
		}
	}

	.bg-stretch {
		height: 664px;
		@extend .flex-middle;
		background-size: cover;
		background-position: 50% 50%;
		background-color: #b2b2b2;
		background-blend-mode: multiply;

		@include media-breakpoint-down(sm) {
			height: 430px;
			align-items: flex-end;
			position: relative;
			overflow: hidden;
		}
	}

	.container-fluid {
		padding-top: 20px;
		padding-bottom: 62px;

		transition: .8s ease all;
		transform: translate(0,50px);
		opacity: 0;

		@include media-breakpoint-down(sm) {
			padding-bottom: 30px;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	&.active .container-fluid {
		opacity: 1;
		transform: translate(0,0);
	}

	&__title {
		color: $white;
		font-size: 60px;
		line-height: 1;
		display: block;
		text-transform: uppercase;
		margin-bottom: 26px;

		@include media-breakpoint-down(md) {
			font-size: 54px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 32px;
			line-height: 1.06;
			margin-bottom: 19px;
		}

		br {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	&__inner {
		max-width: 1000px;
	}

	.btn-holder {
		@extend .flex;
		margin: 0 -5px;

		.btn {
			margin: 0 5px 10px;

			@include media-breakpoint-down(sm) {
				margin: 0 5px 10px;
			}
			@include media-breakpoint-down(xs) {
				margin: 0 3px 10px;
				padding-right: 9px;
				padding-left: 9px;
			}
		}
	}
}

.investor-media {
	padding-top: 96px;
	padding-bottom: 97px;

	@include media-breakpoint-down(sm) {
		padding: 71px 20px;
	}

	@media only screen and (max-width: 450px) {
		padding: 71px 0;
	}

	.h1 {
		line-height: 1.2;
		display: block;
		margin-bottom: 20px;

		@include media-breakpoint-down(sm) {
			line-height: 1.06;
			margin-bottom: 24px;
		}
	}

	.btn {
		min-width: 210px;
		margin-top: 3px;

		@include media-breakpoint-down(sm) {
			min-width: 180px;
		}
	}
}

.wd_widget-theme-light {
	font-family: "MarkOT", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

.wd_widget {
	.wd_selectors {
		display: none;
	}

	.wd_layout-masonry {
		.wd_item {
			position: relative !important;
			left: auto !important;
			top: auto !important;
			width: 100% !important;
			margin-bottom: 10px;
			display: none;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				display: block;
			}
		}
	}

	.wd_quote-wrapper {
		div.wd_quote {
			padding: 0;
			background: none;
			border: none;
			overflow: hidden;
		}
		.wd_quote_footer {
			display: none;
		}

		span.wd_symbol {
			display: inline-block;
		}

		.wd_main {
			display: block;
		}

		div.wd_symbol {
			display: block;
			font-size: 22px;
			line-height: 1.2;
			text-transform: uppercase;
			margin-bottom: 6px;

			span {
				color: #443738;
			}
		}

		.wd_main {
			padding: 0;
		}

		div.wd_price {
			font-size: 30px;
			line-height: 1.2;
			font-weight: 700;

			div.wd_value {
				font-size: 30px;
				line-height: 1.2;
			}
		}

		div.wd_quote-value-up{
			color: #69ac33;
		}
	}
}

div.wd_quote-no_detail div.wd_main-wrapper {
	overflow: hidden;
}

div.wd_quote-wrapper {
	margin-bottom: 30px;
}

.wd_layout-masonry {
	padding-left: 0;
	padding-top: 10px;
	height: auto !important;

	@include media-breakpoint-down(lg) {
		padding-left: 0;
	}
}

.wd_widget-theme-light .wd_layout-masonry .wd_item {
	border: none !important;
	margin-bottom: 40px !important;

	@include media-breakpoint-down(sm) {
		margin-bottom: 29px !important;
	}
}

.wd_widget-theme-light a.wd_item_link_block {
	display: inline-block;
	vertical-align: top;
	width: auto;
	color: inherit;
}

.wd_widget-theme-light a.wd_item_link_block:hover .wd_item_title {
	color: #915040 !important;
	text-decoration: underline;
}

.wd_widget-theme-light .wd_layout-masonry .wd_item_info {
	background: none !important;
	padding: 0 !important;
	display: inline-block;
	vertical-align: top;
}

.wd_widget-theme-light .wd_layout-masonry .wd_item_image{
	display: none;
}

.wd_widget-theme-light .wd_layout-masonry .wd_item_info {
	font-weight: 700;
}

.wd_widget-theme-light .wd_layout-masonry .wd_item_date {
	color: #cd0954 !important;
	font-size: 14px;
	line-height: 1.2;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.wd_widget-theme-light .wd_layout-masonry .wd_item_title {
	transition: color 0.2s linear;
	font-size: 32px;
	line-height: 1.28125;

	@include media-breakpoint-down(sm) {
		font-size: 23px;
		line-height: 1.39;
	}
}

.wd_newsfeed_releases_footer {
	text-align: left;
	padding-left: 0;
	margin: 0;

	.wd_newsfeed_releases_more{
		display:none;
	}

	@include media-breakpoint-down(lg) {
		padding-left: 0;
		margin: 44px 0 0;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 32px;
	}
}

.wd_newsfeed_releases_loading {
	margin-bottom: 40px;
	@media (min-width: 480px) {
		position: absolute;
		bottom: -14px;
		left: 260px;
	}
}

button.wd_button {
	transition: all 0.2s linear !important;
	// display: inline-block !important;
	text-decoration: none !important;
	font-size: 18px !important;
	line-height: 1.2 !important;
	font-weight: 700 !important;
	border-radius: 26px !important;
	border: none !important;
	margin-bottom: 10px !important;
	white-space: normal !important;
	background: $pink !important;
	color: $white !important;
	padding: 15px 30px !important;

	&:hover {
		background: $brown !important;
		box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25) !important;
	}
}

.covid-container{
	color: white;
	// background: #9D4C3C;
	background-color: #915040;
	padding: 34px 0 36px;
	margin: 0 !important;
	border:10px solid $white;

	@include media-breakpoint-down(lg) {
		padding-bottom: 24px;
	}
	@include media-breakpoint-down(sm) {
		padding: 28px 9px 36px;
	}

	&.secondary {
		// border:10px solid $white;
		border-bottom: 0;

		@media (min-width: 767px) {
			border-bottom: 5px solid $white;
			border-top: 5px solid $white;
		}
	}

	.container-fluid {
		max-width: 1327px;
	}

	h3 {
		margin-bottom: 11px;

		@include media-breakpoint-down(sm) {
			font-size: 30px;
			line-height: 1.2;
			margin-bottom: 17px;
		}
	}

	.btn-block {
		text-align: center;

		@media ( max-width:1023px ){
			text-align: left;
		}
	}

	.btn {
		padding-left: 18px;
		padding-right: 18px;
		margin-right: 19px;
		margin-left: auto;
		display: table;
		margin-top: 24px;

		@include media-breakpoint-down(lg) {
			margin-right: 0;
		}

		@include media-breakpoint-down(sm) {
			margin-right: 0;
			margin-top: 0;
			margin-left: 0;
			padding-left: 26px;
			padding-right: 26px;
		}
	}

	p {
		margin-bottom: 0;
		line-height: 1.65;
		margin-right: 50px;

		@include media-breakpoint-down(lg) {
			margin-right: 0;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 31px;
		}
	}

	a.btn.btn-primary,
	a.btn.btn-primary:focus,
	a.btn.btn-primary:active {
		background: #473637;
		color: white;
		border-color: #473637;
		outline: none;
	}

	a.btn.btn-primary:hover {
		@media (min-width: 768px) {
			background-color: #7E3D31;
			border-color: #7E3D31;
			box-shadow: none;
		}
		@media (max-width: 768px) {
			box-shadow: none;
		}
	}

}
