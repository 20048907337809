.goals {
	padding-top: 100px;
	padding-bottom: 2px;

	@include media-breakpoint-down(sm) {
		padding: 106px 4px 24px;
	}

	&__icon {
		display: table;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 12px;

		@include media-breakpoint-down(sm) {
			margin-bottom: -1px;
		}

		img {
			margin-top: -48px;
			width: 56px;
			height: auto;

			@include media-breakpoint-down(sm) {
				width: 38px;
			}
		}
	}

	h2 {
		text-align: center;
		font-size: 32px;
		line-height: 1.2;
		margin-bottom: 45px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 40px;
			font-size: 30px;
			line-height: 1.2;
		}
	}

	h3 {
		margin-bottom: 12px;

		@include media-breakpoint-down(sm) {
			font-size: 20px;
			line-height: 1.2;
		}
	}

	ul {
		@extend %listreset;
		border-top: 1px solid #78b52e;
		margin-bottom: 43px;

		@include media-breakpoint-down(sm) {
			font-size: 16px;
			line-height: 1.5;
			margin-bottom: 38px;
		}

		li {
			padding-top: 9px;
			padding-bottom: 9px;
			border-bottom: 1px solid #78b52e;

			@include media-breakpoint-down(sm) {
				padding-top: 14px;
				padding-bottom: 14px;
			}
		}
	}

	.btn {
		&.btn-sm {
			display: table;
			margin: 51px auto 45px;
			border: none;
			color: #fff;
			padding: 11px 13px;
			min-width: 250px;
			text-align: center;
			font-size: 14px;
			line-height: 1.2;
			border-radius: 20px;
			background-color: #78b52e;

			@include media-breakpoint-down(sm) {
				margin-bottom: 10px;
			}

			&:hover {
				background: #915040;
				box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
			}
		}
	}

	&__box {
		color: #fff;
		padding: 0 110px 47px;
		border-radius: 7px;
		margin-bottom: 100px;
		background-color: rgba(#443738, .8);

		@include media-breakpoint-down(lg) {
			padding: 0 85px 47px;
		}
		@include media-breakpoint-down(md) {
			padding: 0 50px 47px;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 99px;
			padding: 0 29px 47px;
		}

		&--brown {
			.btn {
				&.btn-sm {
					background-color: #7e3d31;
				}
			}

			ul {
				border-color: #dd907c;

				li {
					border-color: #dd907c;
				}
			}
		}

		&--blue {
			.btn {
				&.btn-sm {
					background-color: #26bdef;
				}
			}

			ul {
				border-color: #26bdef;

				li {
					border-color: #26bdef;
				}
			}
		}

		&--pink {
			.btn {
				&.btn-sm {
					background-color: #be0042;
				}
			}

			ul {
				border-color: #d8366e;

				li {
					border-color: #d8366e;
				}
			}
		}
	}
}

