.impact {
	.bg-cover {
		background-position: 50% 50%;
	}

	&__icon {
		width: 44px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 5px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 12px;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	&__topic {
		@extend .flex;
		color: #fff;
		text-align: center;
		background-color: #8b8b8b;
		background-blend-mode: multiply;

		@include media-breakpoint-down(sm) {
			padding-left: 3px;
			padding-right: 3px;
		}

		.container-fluid {
			@extend .flex-middle;
			min-height: 570px;

			@include media-breakpoint-down(sm) {
				min-height: 612px;
			}
		}

		.wrap {
			padding-top: 40px;
			padding-bottom: 60px;
			margin-left: auto;
			margin-right: auto;
			max-width: 778px;

			@include media-breakpoint-down(sm) {
				padding-bottom: 40px;
			}
		}
	}

	p {
		line-height: 1.62;
	}

	h2 {
		font-size: 40px;
		line-height: 1.2;
		margin-bottom: 27px;

		@include media-breakpoint-down(sm) {
			font-size: 30px;
			margin-bottom: 36px;
		}
	}

	&__goals {
		padding: 54px 50px 58px;
		text-align: center;
		background-color: #f4f4f4;

		@include media-breakpoint-down(lg) {
			padding: 54px 20px 58px;
		}
		@include media-breakpoint-down(sm) {
			padding: 59px 52px 50px;
		}
	}

	.h3 {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 13px;

		@include media-breakpoint-down(sm) {
			font-size: 21px;
			line-height: 1.2;
			margin-bottom: 14px;
		}

		a {
			color: #be0042;

			&:hover {
				// text-decoration: none;
				color: #7e3d31;
			}
		}
	}

	.goals__label {
		display: block;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 25px;
		letter-spacing: 0.15em;
		text-transform: uppercase;

		@include media-breakpoint-down(sm) {
			letter-spacing: 0.1em;
			margin-bottom: 13px;
			margin-left: -25px;
			margin-right: -25px;
		}
	}

	.impact__details {
		padding-top: 94px;
		margin-bottom: 111px;

		@include media-breakpoint-down(sm) {
			padding: 79px 3px 0;
			margin-bottom: 100px;
		}

		.h3 {
			margin-bottom: 22px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 16px;
			}
		}

		.pdf {
			padding-top: 58px;

			@include media-breakpoint-down(sm) {
				padding-top: 58px;
			}
		}
	}

	&__list {
		@extend %listreset;
		padding-top: 98px;

		@include media-breakpoint-down(sm) {
			padding-left: 3px;
			padding-right: 3px;
			padding-top: 80px;
		}

		li {
			@extend .flex;
			margin-bottom: 80px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 54px;
			}

			&:last-child {
				margin-bottom: 38px;
			}
		}

		.list__img {
			width: 180px;

			@include media-breakpoint-down(sm) {
				width: 67px;
			}
		}

		.list__content {
			padding-left: 40px;
			width: calc(100% - 180px);

			@include media-breakpoint-down(sm) {
				padding-left: 20px;
				width: calc(100% - 67px);
			}
		}

		p {
			a {
				color: #be0042;

				&:hover {
					// text-decoration: none;
					color: #7e3d31;
				}
			}
		}
	}

	.pdf {
		margin-bottom: 100px;

		@include media-breakpoint-down(sm) {
			padding-top: 23px;
		}

		a {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__icons {
		@extend %listreset;
		@extend .flex-center;
		margin: 0 auto;
		padding-top: 30px;
		max-width: 810px;
		text-align: center;

		@include media-breakpoint-down(md) {
			margin: 0 -10px;
		}
		@include media-breakpoint-down(sm) {
			display: block;
			padding-top: 20px;
		}

		li {
			width: 33.33%;
			padding: 0 10px;
			margin-bottom: 30px;

			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 47px;
			}
		}

		img {
			width: 56px;
			height: auto;

			@include media-breakpoint-down(sm) {
				width: 44px;
			}
		}

		a {
			color: #443738;

			&:hover {
				color: #dd907c;
				// text-decoration: none;
			}
		}
	}

	&__area {
		margin-top: -10px;
		position: relative;
		padding: 122px 20px 82px;
		background-color: #f8f8f8;

		@include media-breakpoint-down(sm) {
			padding: 84px 28px 30px;
			margin-top: 0;
		}

		&:before {
			position: absolute;
			content: '';
			height: 300px;
			top: 0;
			left: -50px;
			right: -50px;
			pointer-events: none;
			box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, .04);
		}

		h4 {
			padding-top: 17px;
			font-size: 26px;
			line-height: 1.2;

			@include media-breakpoint-down(md) {
				font-size: 21px;
				line-height: 1.2;
				padding-top: 11px;
			}
		}

		.impact__icons {
			img {
				@include media-breakpoint-down(sm) {
					width: 56px;
				}
			}
		}

		h2 {
			margin-left: auto;
			margin-right: auto;

			&:before {
				bottom: -6px;
			}
		}
	}
}

.goals-slider {
	&.slick-slider {
		margin-bottom: 0;

		.slick-track {
			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-down(sm) {
				align-items: flex-start;
			}
		}

		.slick-arrow {
			margin-top: -20px;
			top: 50% !important;

			@include media-breakpoint-down(sm) {
				top: 39px !important;
				margin-top: 0;
			}

			&.fixed-position {
				position: absolute !important;
			}

			&.slick-prev {
				left: -50px !important;

				@include media-breakpoint-down(lg) {
					left: -25px !important;
				}
				@include media-breakpoint-down(sm) {
					left: -30px !important;
				}
			}

			&.slick-next {
				left: auto !important;
				right: -50px !important;

				@include media-breakpoint-down(lg) {
					left: auto !important;
					right: -25px !important;
				}
				@include media-breakpoint-down(sm) {
					right: -28px !important;
					left: auto !important;
				}
			}
		}
	}

	.slide {
		&.slick-current {
			+ div {
				> div {
					position: relative;

					&:after,
					&:before {
						background: #d9d7d7;
						position: absolute;
						content: '';
						width: 1px;
						bottom: 12px;
						left: -8px;
						top: 20px;

						@include media-breakpoint-down(sm) {
							display: none;
						}
					}

					&:after {
						left: auto;
						right: -6px;

						@include media-breakpoint-down(md) {
							display: none;
						}
					}
				}

				&:last-child {
					> div {
						&:after {
							display: none;
						}
					}
				}
			}
		}

		> div {
			width: 100%;
			padding: 0 20px;
		}

		p {
			max-width: 327px;
			margin-left: auto;
			margin-right: auto;

			@include media-breakpoint-down(sm) {
				font-size: 16px;
				line-height: 1.7;
			}
		}
	}

	h1 {
		@extend .flex-center;
		@extend .flex-middle;
		width: 100%;
		font-size: 70px;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 16px;
		min-height: 84px;

		@include media-breakpoint-down(sm) {
			font-size: 50px;
			line-height: 1.2;
			min-height: 60px;
		}

		img {
			width: auto;
			height: auto;
			max-height: 84px;

			@include media-breakpoint-down(sm) {
				max-height: 60px;
			}
		}
	}
}