body.section-blog {
	// background-color: #fff;
	
	.hero-lg {

		@media (max-width: 767px) {
			padding: 0px 0 54px;
		}

		.bg-stretch {
			background-color: #BFBFBF;
			background-blend-mode: multiply;
		}
	}
}

.btn {
	&.btn-brown {
		background: #7e3d31;
		color: #fff;
		border:2px solid #7e3d31;

		&:hover {
			background: #473637;
		}
	}
}

.hero-section {
	transition: .2s ease all;

    @include media-breakpoint-down(xl) {
    	margin-left: 0;
    	margin-right: 0;
	}

	@media (max-width: 1599px) and (min-width: 768px) {
		padding: 0 40px;
		margin-left: 0;
		margin-right: 0;
	}

	@media (max-width: 767px) {
		padding: 0;
	}
}

.hero-lg {
	background-size: cover;
	background-repeat: no-repeat;
	padding: 100px 0 130px;
	background-position: 50% 50%;
	position: relative;
	z-index: 0;

    @include media-breakpoint-down(md) {
		padding:60px 0 70px;
	}
    
    @include media-breakpoint-down(sm) {
		padding: 60px 0 54px;
    }

	.hero__text {
		padding: 0;
	}

	h1 {
		margin: 0 0 22px;

    	@include media-breakpoint-down(sm) {
    		margin: 0 0 10px;
    		line-height: 1.3;
		}
	}

	.text {
		font-size: 16px;
		font-weight: 700;
		display: block;
		margin: 0 0 10px;
	}

	&.secondary {
	    margin-bottom: -218px;

	    @include media-breakpoint-down(md) {
			margin-bottom: -187px;
	    }

	    @include media-breakpoint-down(md) {
			margin-bottom: -142px;
	    }

		&:after {
		    min-height: 370px;

		    @include media-breakpoint-down(md) {
		    	min-height: 471px;
		    }

		    @include media-breakpoint-down(sm) {
		    	min-height: 366px;
		    }
		}
	}

	&:after {
		clear: both;
		display: block;
		content: '';
		min-height: 368px;

	    @include media-breakpoint-down(md) {
	    	min-height: 470px;
	    }

	    @include media-breakpoint-down(sm) {
	    	min-height: 380px;
	    }
	}

	.bg-stretch {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		bottom: 0;
		z-index: -1;
	}
}

.story-container {
	.container-fluid {
		padding: 0 40px;

		@media (max-width: 375px) {
	    	padding: 0 20px;
		}
	}

	.heading-wrap {
		padding: 72px 0 30px;
		position: relative;
		z-index: 9;

	    @include media-breakpoint-down(sm) {
			padding: 48px 0 30px;
		}

		.category-list {
			@extend %listreset;
			display: flex;
			flex-wrap:wrap;
			justify-content: center;
			margin: 0 0 13px;

			@include media-breakpoint-down(sm) {
				margin: 0 0 7px;
			}

			li {
				padding: 0 10px;
				font-size: 12px;
				font-weight: 700;
				margin: 0 0 10px;

				@include media-breakpoint-down(sm) {
					font-size: 14px;
				}

				&:not(:last-child) {
					border-right: 1px solid #c1c1c1;
				}
			}

			a {
				color: #be0042;
			}
		}
	}
	

	h2 {
	    @include media-breakpoint-down(sm) {
	    	font-size: 23px;
		}
	}

	.decor {
		position: relative;
		display: inline-block;
		padding: 0 43px;
		vertical-align: top;

		&:after,
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		
		&:after {
			@include size(301px,128px);
			background:url('../../img/splash7.png') no-repeat 0 0 /auto;
			right: 100%;

			@include media-breakpoint-down(xs) {
				@include size(198px,83px);
				background-size: contain;
				right: 86%;
			}
		}

		&:before {
			@include size(238px,201px);
			background:url('../../img/splash8.png') no-repeat 0 0 /auto;
			top: 0;
			left: 100%;

			@include media-breakpoint-down(xs) {
				@include size(155px,130px);
				background-size: contain;
				top: 10px;
				left: 90%;
			}

		}
	}
	
	.btn-wrap {
		text-align: center;
		padding: 20px 0;
		margin: 0 0 40px;
	}
}

.filter-wrap {
	margin: 0 0 44px;

 	@include media-breakpoint-down(md) {
		margin: 0 15px 25px;
    }

    >.row {
    	justify-content: center;
	 	@include media-breakpoint-down(md) {
	 		justify-content: flex-start;
		}
    }

    .filter__block {
    	position: relative;
    	padding: 0 10px;

    	&.active {
    		.filter-list {

			 	@include media-breakpoint-down(md) {
    				border-bottom: 1px solid #707070;
	    			max-height: 999px;
			 	}
    		}
    	}
    }

    .icon-add {
		display: inline-block;
		vertical-align: middle;
		margin: 0 0 4px;
		padding-right: 5px;
	}

    .filter__opener {
    	display: none;
		position: relative;
		font-size: 16px;
		color: #443738;
		margin: 0 0 15px;
		font-weight: 700;

	 	@include media-breakpoint-down(md) {
			border-bottom: 3px solid #be0042;
			display: inline-block;
			vertical-align: top;
	 	}

		&:hover {
			text-decoration: none;
			color: #be0042;
		}
		
		&:focus {
			outline: none;
		}

		&:after {
    		@include triangle(10px 5px, #000, down);
			content: '';
			position: absolute;
			right: -15px;
			top: 50%;
			transform: translateY(-50%);
		}		
    }

	.filter-list {
		@extend %listreset;
		display: flex;
		flex-wrap:wrap;
		width: 100%;

		@include media-breakpoint-down(md) {
			@include animate(max-height opacity);
	    	position: relative;
	    	max-height: 0;
	    	overflow: hidden;
    	}
	
		li {
			font-size: 16px;
			font-weight: 700;
			padding: 0 15px;
			margin: 0 0 15px;

		 	@include media-breakpoint-down(md) {
		 		width: 100%;
		 		padding: 0;
		 	}

			&:only-child {
				padding: 0 20px;
			}
		}

		a {
			display: inline-block;
			color: #443738;
			vertical-align: top;
			font-weight: 700;
			position: relative;

			&:after {
				@include animate(all);
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 3px;
				background:  #be0042;
				opacity: 0;
				width: 0;
				margin: 0 auto;
			}
			
			&:focus {
				outline: none;
			}

			&:hover {
				text-decoration: none;
				// color: #be0042;
				color: #915040;
			}

			&.active {

				&:after {
					width: 100%;
					opacity: 1;
				}

			 	@include media-breakpoint-down(md) {
			 		&:after {
			 			content: none;
			 		}
			 	}
			}

			&.opener {
				font-weight: 400;
				color: #443738;

				&:hover {
					// text-decoration: none;
					// color: #be0042;
					text-decoration: underline;
					color: #dd907c;
				}
			}
		}
	}
}

.search-wrap {
	display: flex;
	flex-wrap:wrap;
	justify-content: flex-end;
	width: 100%;
}

.search-opener {
	@include size(48px);
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	flex-wrap:wrap;
	align-items: center;
	justify-content: center;
	background: #cd0954;
	color: $white;

 	@include media-breakpoint-down(md) {
		@include size(42px);
	}
	

	&:hover {
		text-decoration: none;
		background: #443738;
		color: #fff;
	}
}

.posts-block {
	padding: 86px 0 71px;
	position: relative;
	// background-color: #F6F3F1;
	background-color: #f8f8f8;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
		top: -6px;
		left: 0;
		height: 5px;
		right: 0;
	}

	@include media-breakpoint-down(md) {
		padding: 33px 0;
    }

    &.secondary {
    	padding: 50px 0;
    	position: relative;

		@include media-breakpoint-down(sm) {
			padding: 33px 0;
		}
    }

    .nav-select {
    	display: none;
    	border:none;
    	background:transparent;
    	font-weight: 700;

		@include media-breakpoint-down(md) {
			display: block;
 	   	}
	}


	.link-wrap {
		a {
			font-size: 16px;
			padding: 0 20px;
			display: inline-block;
			vertical-align: top;
			font-weight: 400;
			color: #443738;

			@include media-breakpoint-down(md) {
				padding: 0 10px;
			}

			&:hover {
				// text-decoration: none;
				// color: #be0042;
				color: #dd907c;
			}
			
			&:focus {
				outline: none;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				margin: 0 0 4px;
				padding-right: 5px;
			}
		}
	}

	.post-wrap {
		@include animate(box-shadow);
		background: $white;
		margin: 0 0 40px;
		color: #443738;
		display: block;
		text-decoration: none;
		box-shadow: 0 10px 35px rgba(0,0,0,0);

		&:hover {
			box-shadow: 0 10px 35px rgba(0,0,0,.1);

			.bg-stretch {
				transform: scale(1.1);
			}

			h3 {
				color: #915040;
				text-decoration: underline;
			}
		}
	}

	.img-hold {
		height: 300px;
		display: block;
		overflow: hidden;
		position: relative;

    	@include media-breakpoint-down(md) {
			height: 245px;
		}
	}

	.bg-stretch {
		@include animate(transform, 1.5s,cubic-bezier(.22,.89,.57,1));
		position: absolute;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.text-hold {
		padding: 36px 30px 23px;
	}

	.category {
		font-size: 12px;
		letter-spacing: 1.2px;	
		font-weight: 700;
		display: block;
		margin: 0 0 6px;
		color: #915040;

		span {
			text-transform: uppercase;
		}
	}

	h3 {
		@include animate(color);
		font-size: 22px;
		color: #443738;
		min-height: 67px;
    	
    	@include media-breakpoint-down(md) {
			min-height: 58px;
		}
	}

	time {
		font-size: 12px;
		font-weight: 400;
		text-transform: uppercase;
	}
}

.category-selector {
	.modal-dialog {
		pointer-events:auto;
	}
}

.category-modal {	
	padding: 0 25px;
	.modal-title {
		font-size: 16px;
		color: $white;
		display: block;
		border-bottom: 1px solid #685f5f;
		padding: 10px 0;
		margin: 0 0 25px;
		font-weight: 400;
	    
	    @include media-breakpoint-down(sm) {
			font-size: 12px;
			padding: 4px 0;
		}
	}

	.search-form {
		position: relative;
		margin: 0 0 50px;

    	@include media-breakpoint-down(sm) {
    		margin: 0 0 73px;
		}

		button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 5px;
			background: transparent;
			border:none;
			color: $white;
			padding: 0;
			font-weight: 400;
			font-size: 30px;

	    	@include media-breakpoint-down(sm) {
	    		font-size: 22px;
	    	}

	    	&:hover {
	    		color: #cd0954;
	    	}
		}
	}

	input {
		background:transparent;
		border:none;
		border-bottom: 1px solid  #dd907c;
		font-size: 36px;
		line-height: 1.2;
		color: $white !important;
		width: 100%;
		box-sizing: border-box;
		border-radius: 0;
		padding: 5px 40px 5px 0;

    	@include media-breakpoint-down(sm) {
			padding: 5px 40px 8px 0;
    		font-size: 22px;
		}

		&:focus {
			background: transparent;
			outline: none;
			box-shadow: none;
    		border-bottom-color:  #dd907c;
		}

		@include placeholder {
			color: $white;
		}
	}

	.category-list {
		@extend %listreset;
		margin: 0 0 40px;

		li {
			font-size: 36px;

	    	@include media-breakpoint-down(sm) {
				font-size: 22px;
				margin: 0 0 11px;
	    	}
		}

		a {
			@include animate(color);
			color: $white;
			// text-decoration: none;

			&:hover,
			&.active {
				// text-decoration: none;
				// color: #cd0954;
				color: #dd907c;
			}
			
			&:focus {
				outline: none;
			}
		}
	}
}

.pagination-wrap {
	padding: 15px 0 50px;

	.pagination {
		@extend %listreset;
		display: flex;
		justify-content: center;
		flex-wrap:wrap;

		li {
			padding: 0 10px;
			text-transform: uppercase;
			font-weight: 700;
			color: #443738;
			font-size: 14px;
			margin: 0 0 15px;
		}

		a {
			@include animate(color);
			color: #be0042;
			position: relative;
			display: inline-block;
			vertical-align: top;
			font-size: 14px;

			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 1px;
				background: #be0042;
			}


			&:hover {
				color: #443738;
				text-decoration: none;
			}

			&.prev,
			&.next {
				text-decoration: none;

				.icon-next,
				.icon-prev {
					font-size: 12px;
					font-weight: 700;
				}

				&:after {
					content: none;
				}
			}

			&.prev {
				margin-right: 25px;

				@include media-breakpoint-down(sm) {
					margin-right: 0;
				}
			}

			&.next {
				margin-left: 25px;

				@include media-breakpoint-down(sm) {
					margin-left: 0;
				}
			}
		}
	}
}

.bg-gray {
	background: #F3F3F3;
}


.article-wrap {
	padding: 45px 40px 160px;
	position: relative;

	@include media-breakpoint-down(md) {
		padding: 45px 40px;
	}

	@media (max-width: 450px) {
		padding: 45px 20px;
	}

	.article-content {
		max-width: 1280px;
		margin: 0 auto;
		width: 100%;
		background: $white;
		padding: 77px 70px 45px;

		@include media-breakpoint-down(md) {
			padding: 77px 60px 35px;
		}

		@include media-breakpoint-down(sm) {
			padding: 55px 18px 35px;
		}
	}

	h1 {
		font-weight: 700;

		@include media-breakpoint-down(sm) {
			margin: 0 0 3px;
		}
	}

	.title-content {
		margin: 0 0 57px;

		@include media-breakpoint-down(sm) {
			margin: 0 0 41px;
		}

		time {
			font-size: 12px;
			text-transform: uppercase;
			color: #443738;
		}
	}

	.category-list {
		@extend %listreset;
		display: flex;
		flex-wrap:wrap;
		justify-content: center;
		margin: 0 0 13px;

		@include media-breakpoint-down(sm) {
			margin: 0 0 7px;
		}

		li {
			padding: 0 10px;
			font-size: 12px;
			font-weight: 700;
			margin: 0 0 10px;

			@include media-breakpoint-down(sm) {
				font-size: 14px;
			}

			&:not(:last-child) {
				border-right: 1px solid #c1c1c1;
			}
		}

		a {
			color: #be0042;
			
			&:focus {
				outline: none;
			}
			&:hover {
				color: #915040;
			}
		}
	}

	.content-hold {
		max-width: 840px;
		margin: 0 auto 97px;
		width: 100%;
		overflow: hidden;

		@include media-breakpoint-down(sm) {
			margin:0 auto 20px;
		}

		p {
			line-height: 2.05;
			margin: 0 0 40px;

			@include media-breakpoint-down(sm) {
				line-height: 1.8;
			}
		}

		.image-wrap {
			padding: 47px 0;
		}
	}

	.social-networks {
		@extend %listreset;
		display: flex;
		flex-wrap:wrap;
		justify-content: center;

		li {
			font-size: 32px;
			padding: 0 10px;
		}
		
		a {
			color: #443738;

			&:hover {
				color: #be0042;
				text-decoration: none;
			}
		}
	}
}