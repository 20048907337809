@font-face {
    font-family: 'MarkOT';
    src: url('../../fonts/MarkOT-Bold.woff2') format('woff2'),
        url('../../fonts/MarkOT-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MarkOT';
    src: url('../../fonts/MarkOT.woff2') format('woff2'),
        url('../../fonts/MarkOT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}