#footer {
	background: $black;

	position: relative;

	// &:before {
	//   content: '';
	//   background: linear-gradient(to right, rgba(205,9,84,1) 0%,rgba(196,21,94,1) 20%,rgba(60,199,242,1) 20%,rgba(60,199,242,1) 40%,rgba(145,80,64,1) 40%,rgba(145,80,64,1) 60%,rgba(122,193,66,1) 60%,rgba(119,184,65,1) 80%,rgba(68,55,56,1) 80%,rgba(68,55,56,1) 100%);
	//   position: absolute;
	//   top: 0;
	//   left: 0;
	//   right: 0;
	//   height: 6px;
	// }

	.color-band {
		@include make-row();

		.color-band__col {
			@include make-col-ready();
			@include make-col(2.4);
			height: 6px;

			&--red {
				background-color: #cd0954;
			}
			&--blue {
				background-color: #3cc7f2;
			}
			&--bronze {
				background-color: #915040;
			}
			&--green {
				background-color: #7ac142;
			}
			&--brown {
				background-color: #443738;
			}
		}
	}

	.container {
		position: relative;
		z-index: 2;
	}

	.footer-wrapper {
		padding: 78px 40px 114px;

		@include media-breakpoint-down(md) {
			padding: 46px 20px;
		}

		@include media-breakpoint-down(sm) {
			padding: 106px 0 389px;
		}
	}

	.social-networks {
		@extend %listreset;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		margin: 4px -12px 24px;

		@include media-breakpoint-down(md) {
			justify-content: center;
			margin: 0 0 39px;
		}

		&.sm-view {
			display: none;

			@include media-breakpoint-down(md) {
				display: flex;
			}
		}

		&.lg-view {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		li {
			padding: 0 12px;
			margin-bottom: 10px;
			font-size: 32px;
			line-height: 1;
		}

		a {
			color: $white;
			// text-decoration: none;

			&:hover {
				color: $pink;
			}
		}
	}

	.footer-logo {
		margin-top: -3px;
		width: 111px;
		display: table;
		margin-left: auto;
		margin-bottom: 32px;

		@include media-breakpoint-down(sm) {
			margin-left: 0;
			margin-top: -1px;
			margin-bottom: 8px;
		}
	}

	.info {
		color: #f4f4f4;
		font-size: 12px;
		line-height: 2;
		text-align: right;

		@include media-breakpoint-down(sm) {
			text-align: left;
		}

		address {
			display: block;
			margin: 0;

			a {
				color: $white;
				// text-decoration: none;

				&:hover {
					color: $pink;
				}
			}
		}
	}

	.footer-splash {
		position: absolute;
		text-align: right;
		right: -190px;
		bottom: -114px;
		left: 0;
		
		@include media-breakpoint-down(md) {
			bottom: -74px;
		}
		@include media-breakpoint-down(sm) {
			right: -113px;
			bottom: 63px;
		}
		@include media-breakpoint-down(xs) {
			right: -213px;
			left: -165px;
		}

		img {
			max-width: 60%;
			vertical-align: top;

			@include media-breakpoint-down(sm) {
				max-width: 753px;
				width: 100%;
				height: auto;
			}
			
		}
	}
}

.footer-nav {
	@extend %listreset;
	font-size: 14px;
	line-height: 1.57143;
	font-weight: 400;

	@include media-breakpoint-down(sm) {
		margin-bottom: 39px;
	}

	.h3 {
		color: #dd907c;
		font-size: 18px;
		line-height: 1.2;
		display: block;
		margin-bottom: 7px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 10px;
		}

		&:only-child {
			margin-bottom: 5px;
		}

		a {
			color: inherit;

			&:hover {
				color: $pink;
			}
		}
	}

	> li {
		padding-bottom: 36px;
		margin-bottom: 47px;
		border-bottom: 1px solid rgba(#dd907c, .3);

		@include media-breakpoint-down(sm) {
			padding-bottom: 29px;
			margin-bottom: 39px;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: none;

			@include media-breakpoint-down(sm) {
				border-bottom: 1px solid rgba(#dd907c, .3);
				padding-bottom: 28px;
				margin-bottom: -2px;
			}
		}
	}

	a {
		color: $white;
		// text-decoration: none;

		&:hover {
			color: #dd907c;
		}
	}

	ul {
		@extend %listreset;

		li {
			margin-bottom: 6px;
		}
	}
}