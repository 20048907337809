.content-wrapper {
	background-color: #f8f8f8;
	padding-top: 80px;
	position: relative;

	@include media-breakpoint-down(md) {
		padding-top: 40px;
	}
	@include media-breakpoint-up(md) {
		padding-left: 20px;
		padding-right: 20px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
	}

	&:before {
		position: absolute;
		content: '';
		height: 300px;
		top: 0;
		left: -50px;
		right: -50px;
		pointer-events: none;
		box-shadow: inset 0 5px 5px 2px rgba(0, 0, 0, .01);
	}

	.slick-slider {
		.slick-arrow {
			background-size: 100% auto;

			&.slick-prev {
				left: 20px;
				background-image: url('../../img/arrow-left-p.svg');
			}

			&.slick-next {
				right: 20px;
				left: auto;
				background-image: url('../../img/arrow-right-p.svg');
			}

			img {
				opacity: 0;
			}
		}

		.slick-dots {
			li {
				&.slick-active {
					button {
						background: #cd0954;
					}
				}
			}

			button {
				background: rgba(#cd0954, .6);
			}
		}
	}
}

/* company content styles */
.content-wrapper__content {
	margin-left: 2px;
	margin-right: 10px;
	margin-bottom: 103px;

	@include media-breakpoint-down(md) {
		margin-bottom: 65px;
		margin-left: 0;
	}
	@include media-breakpoint-down(sm) {
		padding-left: 20px;
		padding-right: 20px;
		margin-right: 0;
		margin-bottom: 73px;
	}
	@media only screen and (max-width: 450px) {
		padding: 0;
	}

	h3, .h3 {
		display: block;
		font-size: 30px;
		line-height: 1.2;
		margin-bottom: 40px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 38px;
		}
	}

	p {
		margin-bottom: 36px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 32px;
		}
	}
}

/* stats styles */
.stats {
	@extend .flex;
	@extend %listreset;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	line-height: 1.625;
	position: relative;
	left: -18px;
	margin-bottom: 49px;

	@include media-breakpoint-down(lg) {
		left: 0;
	}
	@include media-breakpoint-down(md) {
		max-width: 448px;
		margin: 0 auto 42px;
	}
	@include media-breakpoint-down(sm) {
		font-size: 16px;
		line-height: 1.625;
		padding: 0 20px;
		margin-bottom: 31px;
	}
	@media only screen and (max-width: 450px) {
		padding: 0;
	}

	li {
		width: 25%;
		padding: 25px 15px 16px;

		@include media-breakpoint-down(lg) {
			width: 25%;
		}
		@include media-breakpoint-down(md) {
			width: 50%;
		}
		@include media-breakpoint-down(sm) {
			padding: 35px 15px 18px;
		}

		> * {
			@include media-breakpoint-down(sm) {
				margin-left: -20px;
				margin-right: 0;
			}
		}

		&:nth-child(even) {
			@include media-breakpoint-down(md) {
				border-left: 1px solid rgba($black, 0.5);
			}

			> * {
				@include media-breakpoint-down(sm) {
					margin-right: -20px;
					margin-left: 0;
				}
			}
		}

		+ li {
			@include media-breakpoint-up(lg) {
				border-left: 1px solid rgba($black, 0.5);
			}

			+ li {
				@include media-breakpoint-down(md) {
					border-top: 1px solid rgba($black, 0.5);
				}
			}
		}
	}

	.h1 {
		font-weight: 700;
		color: $brown;
		display: block;
		margin-bottom: 2px;

		@include media-breakpoint-down(sm) {
			font-size: 40px;
			line-height: 1.2;
			margin-bottom: 5px;
		}
	}

	p {
		margin-bottom: 16px;
	}
}

.content-wrapper__lead {
	font-size: 1.625rem;
	line-height: 1.6;
	display: block;
	margin-bottom: 74px;

	@include media-breakpoint-down(sm) {
		font-size: 1.375rem;
		line-height: 1.7273;
	}
}
