.reporting {
	background-color: #f8f8f8;
	position: relative;
	padding-top: 98px;
	padding-bottom: 1px;
	padding-bottom: 143px;

	@include media-breakpoint-down(md) {
		padding-bottom: 154px;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 80px;
	}

	&:before {
		position: absolute;
		content: '';
		height: 300px;
		top: 0;
		left: -50px;
		right: -50px;
		pointer-events: none;
		box-shadow: inset 0 5px 5px 2px rgba(0, 0, 0, .01);
	}

	h1 {
		line-height: 1.1;
		font-size: 50px;
		font-weight: 700;
		margin-bottom: 3px;
	}

	h2 {
		font-size: 20px;
		font-weight: 700;
		line-height: 1.2;
		margin-bottom: 12px;
	}

	&__year {
		margin-bottom: 87px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 58px;
		}
	}

	&__list {
		@extend %listreset;
		border-top: 1px solid #443738;
		font-size: 16px;
		line-height: 1.8;
		margin-bottom: 38px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 48px;
		}

		li {
			padding: 9px 0;
			border-bottom: 1px solid #d9d7d7;

			@include media-breakpoint-down(sm) {
				padding: 11px 0 10px;
			}
		}
	}
}