.general-page-wrapper {
	padding: 70px 0 110px;

	@include media-breakpoint-down(sm) {
		padding: 70px 20px 30px;
	}
	@media only screen and (max-width: 450px) {
		padding: 70px 0 30px;
	}

	h1 {
		text-transform: uppercase;
		margin-bottom: 60px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 20px;
		}
	}

	p {
    margin-bottom: 32px;

		@include media-breakpoint-down(sm) {
			margin-left: 2px;
		}
	}

	h2 {
		font-size: 30px;
		margin-bottom: 40px;

		@include media-breakpoint-down(sm) {
			font-size: 24px;
			margin-bottom: 36px;
		}
	}

	ul {
		margin-bottom: 30px;
	}

	address {
		margin-bottom: 0;
	}

	.tel {
		color: $black;
		// text-decoration: none;

		&:hover {
			color: $pink;
		}
	}

	strong {
		// display: block;
	}
}

.general-page__title {
	max-width: 1050px;
	margin-bottom: 68px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 66px;
	}
}

/* office timing styles */
.office-time {
	padding-top: 36px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 32px;
	}

	span {
		display: block;
	}
}

.mail-hold {
	margin-bottom: 36px;

	@include media-breakpoint-down(sm) {
		margin-bottom: 34px;
	}
}

/* address styles */
.contact__address {
	margin-bottom: 40px;

	@include media-breakpoint-down(sm) {
		margin-left: 2px;
		margin-bottom: 66px;
	}
}