$small-type: 16px;

.bg-cover {
	background-size: cover;
	background-position: 50% 0;
	background-repeat: no-repeat;
}

.corporate_page {
	// #header {
	// 	padding: 24px 33px;
	// 	min-height: 106px;

	// 	@include media-breakpoint-down(sm) {
	// 		padding: 12px 32px;
	// 		min-height: 60px;
	// 	}
	// }

	// .logo {
	// 	width: 172px;
	// 	top: 29px;
	// 	left: 33px;

	// 	@include media-breakpoint-down(sm) {
	// 		width: 125px;
	// 		top: 12px;
	// 		left: 32px;
	// 	}
	// }

	// .hamburger {
	// 	@include media-breakpoint-down(sm) {
	// 		top: 18px;
	// 		right: 36px;
	// 	}
	// }

	// .hamburger-box {
	// 	@include media-breakpoint-down(sm) {
	// 		width: 28px;
	// 		height: 18px;
	// 	}
	// }

	// .hamburger-inner {
	// 	@include media-breakpoint-down(sm) {
	// 		width: 28px;
	// 	}

	// 	&:before,
	// 	&:after {
	// 		@include media-breakpoint-down(sm) {
	// 			width: 28px;
	// 			top: -16px;
	// 		}
	// 	}

	// 	&:before {
	// 		@include media-breakpoint-down(sm) {
	// 			top: -8px;
	// 		}
	// 	}
	// }

	// .nav-bar {
	// 	@include media-breakpoint-down(md) {
	// 		top: 106px;
	// 	}

	// 	@include media-breakpoint-down(sm) {
	// 		top: 60px;
	// 	}

	// 	.inner {
	// 		> ul {
	// 			> li {
	// 				font-size: 1rem;

	// 				+ li {
	// 					margin-left: 42px;

	// 					@include media-breakpoint-down(md) {
	// 						margin-left: 0;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	.hero {
		margin-bottom: 2px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 1px;
		}

		&--no-logo {
			padding-top: 79px;
			padding-bottom: 107px;

			@include media-breakpoint-down(sm) {
				padding-top: 31px;
				padding-bottom: 33px;
			}

			h1 {
				font-size: 41px;
				margin-bottom: 28px;

				@include media-breakpoint-down(sm) {
					font-size: 30px;
					line-height: 1;
					margin-bottom: 20px;
				}
			}
		}

	}
}

.banner {
	.bg-cover {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}

	.slick-slider {
		margin-bottom: 0;

		@include media-breakpoint-down(sm) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.slick-slide {
		&.slick-active {
			.banner__content {
				transform: translate(0, 0);
				opacity: 1;
			}
		}
	}

	&__outer {
		background-size: cover;
		background-position: 50% 0;
		width: 100%;
	}

	&__holder {
		// // opacity: 0;
		// // transform: translate(0, 50px);
		// transition: transform .8s ease,  opacity .8s ease;
		max-width: 760px;
		margin: 0 auto;
	}

	&__content {
		color: $white;
		text-align: center;
		padding-top: 78px;
		padding-bottom: 108px;
		font-size: 20px;
		line-height: 1.25;

		@include media-breakpoint-down(md) {
			padding-left: 40px;
			padding-right: 40px;
		}
		@include media-breakpoint-down(sm) {
			padding: 65px 32px 121px;
			font-size: 16px;
		}
	}

	&__title, &__btn, p {
		transform: translate(0, 50px);
		opacity: 0;

		.active & {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&.active p {
		transform: translate(0, 0);
		opacity: 1;
	}

	&__title {
		max-width: 720px;
		margin-left: auto;
		margin-right: auto;
		transition: transform .8s ease 0s,  opacity .8s ease 0s;
	}
	p {
		transition: transform .8s ease 250ms,  opacity .8s ease 250ms;
	}
	&__btn {
		transition: transform .8s ease 500ms,  opacity .8s ease 500ms, background-color .2s ease, box-shadow .2s ease;
	}

	&__title {
		font-size: 100px;
		line-height: 1;
		display: block;
		letter-spacing: -7px;
		// margin-bottom: 25px;

		@include media-breakpoint-down(sm) {
			font-size: 63px;
			letter-spacing: -4px;
			margin-bottom: 24px;
		}
	}

	p {
		font-size: 20px;
		line-height: 1.8;
		margin-bottom: 42px;

		@include media-breakpoint-down(sm) {
			line-height: 1.5;
			font-size: 16px;
			margin-left: 22px;
			margin-right: 22px;
			margin-bottom: 57px;
		}
	}

	&__btn {
		position: relative;
		font-size: 18px;
		line-height: 1.2;
		color: #fff;
		background: $pink;
		border-radius: 40px;
		text-decoration: none;
		display: inline-block;
		vertical-align: top;
		padding: 20px 55px 19px 25px;
		width: 166px;
		text-align: left;
		font-weight: 700;

		&:hover {
			background-color: #915040;
			color: #fff;
			box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
		}

		// @include media-breakpoint-down(sm) {
		// 	padding: 11px 55px 8px 25px;
		// 	font-size: 20px;
		// 	width: 176px;
		// }

		.icon-play {
			position: absolute;
			top: 50%;
			right: 18px;
			font-size: 30px;
			line-height: 1;
			transform: translateY(-50%);
		}

		.txt {
			display: block;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.slick-arrow {
		@include animate(color);
		background: none;
		border: none;
		padding: 0;
		color: $white;
		font-size: 48px;
		line-height: 1;
		width: auto;
		height: auto;
		// outline: none;
		top: 53.4%;


		@include media-breakpoint-down(sm) {
			display: none !important;
		}

		&:hover {
			background: none;
			color: #cd0954;
		}

		&:focus {
			outline: 2px auto -webkit-focus-ring-color;
		}

		&.slick-prev {
			left: 32px;

			@include media-breakpoint-down(md) {
				left: 20px;
			}
		}

		&.slick-next {
			right: 34px;

			@include media-breakpoint-down(md) {
				right: 20px;
			}
		}
	}

	.slick-dots {
		padding-top: 0;
		top: auto;
		bottom: 25px;

		@include media-breakpoint-down(sm) {
			bottom: 68px;
		}

		li {
			margin: 0 7px 15px;

			&.slick-active {
				button {
					background: #cd0954;
					cursor: default;
				}
			}
		}

		button {
			width: 12px;
			height: 12px;
			border: 1px solid $white;
			background: $white;

			&:hover {
				background: #cd0954;
			}
		}
	}
}

.title-with-line {
	h2 {
		color: #443738;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0.1em;
		line-height: 1.2;
		text-transform: uppercase;
		position: relative;
		display: table;
		margin-bottom: 36px;

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			letter-spacing: 0.1em;
			line-height: 1.2;
		}

		&:before {
			bottom: -8px;
			left: 0;
			right: 2px;
			height: 3px;
			content: '';
			position: absolute;
			background-color: #dd907c;
		}
	}
}

.ambition-section {
	line-height: 1.6;
	padding-top: 99px;
	padding-bottom: 59px;

	@include media-breakpoint-down(sm) {
		padding-top: 88px;
		padding-bottom: 25px;
		padding-left: 4px;
		padding-right: 4px;
	}

	h2 {
		margin-bottom: 36px;

		@include media-breakpoint-down(sm) {
			margin-bottom: 41px;
		}
	}

	h3 {
		font-size: 34px;
		line-height: 1.12;
		margin-bottom: 31px;

		@include media-breakpoint-down(sm) {
			font-size: 21px;
			line-height: 1.2;
			margin-bottom: 31px;
		}
	}

	p {
		line-height: 1.6;
		margin-bottom: 32px;

		@include media-breakpoint-down(sm) {
			line-height: 1.62;
			margin-bottom: 28px;
		}
	}

	.txt {
		margin-right: 50px;

		@include media-breakpoint-down(lg) {
			margin-right: 0;
		}
	}

	a {
		// text-decoration: none;
		position: relative;
		padding-right: 18px;
	}

	&.fixed-position {
		.splash-holder {
			padding-top: 20px;
		}
	}

	.splash-holder {
		margin-right: -123px;
		margin-top: -56px;
		text-align: right;

		@media only screen and (max-width: 1319px) {
			margin-right: 0;
			margin-top: 0;
		}

		@include media-breakpoint-down(sm) {
			text-align: center;
			margin-bottom: 83px;
		}
	}

	.col-wrap {
		transition: transform .8s ease, opacity .8s ease;
		transform: translate(0, 50px);
		opacity: 0;
	}

	&.active {
		.col-wrap {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	.two-cols {
		padding-top: 46px;

		@include media-breakpoint-down(sm) {
			padding-top: 76px;
		}

		> .row {
			> div {
				&:nth-child(odd) {
					.item {
						position: relative;
						padding-right: 20px;

						@include media-breakpoint-down(sm) {
							padding-right: 0;
							padding-bottom: 15px;
						}

						&:after {
							top: 10px;
							right: 0;
							bottom: 8px;
							width: 1px;
							content: '';
							position: absolute;
							background: #d9d7d7;

							@include media-breakpoint-down(sm) {
								top: auto;
								left: 0;
								right: 0;
								bottom: 0;
								height: 1px;
								width: 100%;
							}
						}
					}
				}
			}
		}

		h2 {
			color: #be0042;
			font-size: 40px;
			font-weight: 400;
			line-height: 1.2;
			margin-bottom: 10px;
			text-transform: uppercase;

			@include media-breakpoint-down(md) {
				font-size: 36px;
			}
			@include media-breakpoint-down(sm) {
				font-size: 32px;
				line-height: 1.2;
				margin-bottom: 18px;
			}
		}

		.item {
			margin-bottom: 30px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 39px;
			}
		}
	}
}

.impact-area {
	z-index: 1;
	text-align: center;
	position: relative;
	padding: 177px 20px 170px;

	@include media-breakpoint-down(sm) {
		padding: 177px 4px 60px;
		text-align: left;
	}
	@include media-breakpoint-down(xs) {
		padding-top: 531px;
		padding-bottom: 20px;
	}

	p {
		@include media-breakpoint-down(sm) {
			line-height: 1.62;
		}
	}

	.title-with-line {
		display: table;
		margin: 0 auto;

		@include media-breakpoint-down(sm) {
			margin: 0;
		}

		h2 {
			margin-bottom: 34px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 42px;
			}

			&:before {
				bottom: -6px;

				@include media-breakpoint-down(sm) {
					bottom: -9px;
				}
			}
		}
	}

	h3 {
		font-size: 34px;
		line-height: 1.2;
		margin-bottom: 29px;

		@include media-breakpoint-down(sm) {
			font-size: 21px;
			line-height: 1.2;
		}
	}

	h4 {
		padding-top: 17px;
		font-size: 26px;
		line-height: 1.2;

		@include media-breakpoint-down(md) {
			font-size: 21px;
			line-height: 1.2;
			padding-top: 11px;
		}
	}

	.bg-cover {
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&__content {
		padding: 77px 20px 48px;
		background: rgba($white, .9);

		@include media-breakpoint-down(sm) {
			padding: 58px 28px 12px;
		}
	}

	&__icons {
		@extend %listreset;
		@extend .flex-center;
		margin: 0 auto;
		padding-top: 24px;
		max-width: 1085px;
		text-align: center;

		@include media-breakpoint-down(md) {
			margin: 0 -10px;
		}
		@include media-breakpoint-down(sm) {
			display: block;
			padding-top: 27px;
		}

		li {
			width: 25%;
			padding: 0 10px;
			margin-bottom: 30px;

			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 47px;
			}
		}

		img {
			width: 55px;
			height: auto;
			transition: .2s linear transform;
		}

		a {
			display: block;
			color: #443738;

			&:hover {
				color: #915040;
				// text-decoration: none;

				img {
					@media (min-width: 768px) {
						transform: scale(.8);
					}
				}
			}
		}
	}
}

.partner {
	// font-size: $small-type;
	padding-top: 57px;
	padding-bottom: 70px;

	@include media-breakpoint-down(sm) {
		padding: 87px 4px 79px;
	}

	.heading {
		margin-bottom: 54px;
		max-width: 840px;
		margin-right: auto;
		margin-left: auto;
		text-align: center;

		@include media-breakpoint-down(sm) {
			text-align: left;
			margin-bottom: 52px;
		}

		p {
			line-height: 1.62;
		}
	}

	h2 {
		margin-bottom: 38px;
		text-align: center;
		display: table;
		font-size: 16px;
		line-height: 1.2;
		letter-spacing: 0.1em;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-down(sm) {
			margin: 0 0 42px;
			font-size: 14px;
		}

		&:before {
			bottom: -6px;

			@include media-breakpoint-down(sm) {
				bottom: -9px;
			}
		}
	}

	h3 {
		font-size: 26px;
		line-height: 1.12;
		margin-bottom: 29px;

		@include media-breakpoint-down(sm) {
			font-size: 21px;
			line-height: 1.2;
			margin-bottom: 28px;
		}
	}

	&__list {
		@extend %listreset;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		@include media-breakpoint-down(md) {
			margin: 0 -10px;
		}

		li {
			max-width: 20%;
			padding: 0 20px;
			margin-bottom: 15px;
			text-align: center;
			position: relative;

			@include media-breakpoint-down(md) {
				max-width: 25%;
				justify-content: center;
				margin-bottom: 20px;
				padding: 0 10px;
			}
			@include media-breakpoint-down(sm) {
				max-width: 50%;
			}

			&.pop-active {
				.pop-drop {
					visibility: visible;
					opacity: 1;
				}
			}

			img {
				display:inline-block;
				vertical-align: top;
				max-height: 80px;
				width: auto;

				@include media-breakpoint-down(sm) {
					max-height: 53px;
				}
			}

			&:first-child {
				.pop-drop {
					left: 100%;

					.inner {
						&:after {
							right: auto;
							left: 90px;
						}
					}
				}
			}

			&:last-child {
				.pop-drop {
					left: 0;

					.inner {
						&:after {
							right: 80px;
						}
					}
				}
			}
		}
	}

	.pop-drop {
		box-shadow: 0 0 18px rgba(#333, 0.2);
		width: 394px;
		position: absolute;
		bottom: 100%;
		left: 30%;
		text-align: left;
		font-size: 12px;
		line-height: 1.33;
		margin-bottom: 24px;
		transform: translateX(-50%);
		visibility: hidden;
		opacity: 0;
		transition: visibility, opacity 0.3s ease-in-out;
		z-index: 999;

		@include media-breakpoint-down(md) {
			width: 300px;
			left: 50%;
		}
		

		&:before {
			content: '';
			box-shadow: 0 9999px 0 9999px rgba(0, 0, 0, .15);
			background: rgba(0, 0, 0, .15);
			position: absolute;
			top: -9999px;
			bottom: -9999px;
			z-index: -1;
		}

		.inner {
			background: $white;
			position: relative;
			padding: 32px 31px;

			&:after {
				content: '';
				background: $white;
				box-shadow: 0 0 18px rgba(#333, 0.2);
				width: 50px;
				height: 50px;
				position: absolute;
				bottom: -6px;
				right: 118px;
				transform: rotate(59deg) skew(30deg);
				z-index: -1;
			}
		}


		.logo-hold {
			margin-bottom: 12px;
		}

		.text-hold {
			margin-bottom: 16px;
		}

		p {
			line-height: 1.33;
			margin-bottom: 11px;
		}

		a {
			// text-decoration: none;
			transition: none;
		}
	}
}

/* grayscale image styles */

img.grayscale {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	filter: gray;
	-webkit-filter: grayscale(100%);

	&:hover {
		filter: none;
	}
}

.grayscale-fix {
	svg {
		top: 0;
		left: 0;
	}
}

.connect {
	padding-top: 39px;
	padding-bottom: 59px;

	@include media-breakpoint-down(sm) {
		padding-bottom: 52px;
	}

	h3 {
		font-size: 28px;
		margin-bottom: 6px;
		font-weight: 400;
		color: #f68700;
		text-align: center;
		margin-bottom: 23px;

		@include media-breakpoint-down(sm) {
			font-size: 20px;
			margin-bottom: 13px;
		}
	}

	.social-networks {
		@extend %listreset;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		li {
			padding: 0 8px;
			margin-bottom: 10px;
		}

		a {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			background: #f3f3f3;
			text-decoration: none;
			color: $black;
			width: 54px;
			height: 54px;
			border-radius: 100%;
			padding: 2px 2px 6px;

			&:hover {
				background: darken(#f3f3f3, 10%);
			}
		}

		.ico {
			font-size: 24px;
			line-height: 1;
		}

		.icon-linkedin {
			font-size: 28px;
		}
	}
}

.pdf {
	padding-top: 14px;

	a {
		@extend .flex;
		padding: 11px;
		width: 100%;
		max-width: 319px;
		border-radius: 4px;
		color: #be0042;
		border: 1px solid #d9d7d7;

		&:hover {
			text-decoration: none;
			// background: rgba(#d9d7d7, .1);
			color: #915040;
		}
	}

	.year {
		@extend .flex-middle;
		@extend .flex-end;
		font-size: 40px;
		font-weight: 700;
		line-height: 1.2;
		padding-right: 7px;
		width: 109px;
		padding-top: 5px;
		border-right: 1px solid #d9d7d7;
	}

	.name {
		font-size: 14px;
		line-height: 1.6;
		font-weight: 700;
		padding-left: 8px;
		padding-top: 7px;
		padding-bottom: 7px;
		width: calc(100% - 109px);

		span {
			font-weight: 400;
		}
	}
}