#wrapper {
  background: $white;
  position: relative;
  overflow: hidden;
  width: 100%;
  
  // ## max-width: 1600px;
  max-width: 1440px;

  margin: 0 auto;
}

/* global button styles */
.btn {
  text-decoration: none;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
  border-radius: 26px;
  border: 2px solid;
  margin-bottom: 10px;
  white-space: normal;
  -webkit-font-smoothing: antialiased;

  &.btn-primary {
    background: $pink;
    border-color: transparent;

    &:hover {
      background: $brown;
      border-color: transparent;
      box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
    }
  }

  &.btn-secondary {
    background: rgba(#443738, .7);
    border-color: transparent;

    &:hover {
     background: #443738;
     border-color: transparent;
     box-shadow: 0 15px 15px rgba(0, 0, 0, 0.25);
   }
 }

 &.btn-lg {
  border-radius: 34px;
    // font-size: 22px;
    // padding: 20px 30px;
    padding: 18px 25px;

    @include media-breakpoint-down(md) {
      padding: 15px 26px;
    }

    &.btn-news {
     padding: 18px 25px;
     min-width: 210px;
     margin-bottom: 40px;
     box-shadow: none;

      @include media-breakpoint-down(xs) {
        padding: 15px 26px;
        min-width: 0;
      }
   }

   @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

&.btn-md {
  padding: 14px 24px;
}
}

img {
  max-width: 100%;
  height: auto;
}

// Grid
.container-fluid {
  @media (min-width: 1340px) {
    max-width: 1320px;
  }
}
