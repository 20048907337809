@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?a8uke9');
  src:  url('../../fonts/icomoon.eot?a8uke9#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?a8uke9') format('truetype'),
    url('../../fonts/icomoon.woff?a8uke9') format('woff'),
    url('../../fonts/icomoon.svg?a8uke9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-add:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-pinterest:before {
  content: "\e903";
}
.icon-twitter:before {
  content: "\e904";
}
.icon-arrow-next:before {
  content: "\e905";
}
.icon-arrow-prev:before {
  content: "\e906";
}
.icon-arrow-sm:before {
  content: "\e907";
}
.icon-minus:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-play:before {
  content: "\e90a";
}
.icon-linkedin-r:before {
  content: "\e90b";
}
.icon-search:before {
  content: "\e90c";
}
.icon-next:before {
  content: "\e910";
}
.icon-back:before {
  content: "\e911";
}
.icon-globe:before {
  content: "\e912";
}
.icon-facebook1:before {
  content: "\ea90";
}
.icon-linkedin:before {
  content: "\eaca";
}
