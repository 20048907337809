.browser-upgrade-message {

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  z-index: 10001;
  text-align: center;
  color: #fff;

  background-color: $black;

  a {
    color: #fff;
    text-decoration: underline;
  }

}

.browser-upgrade-message__container {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -70px;
  padding-left: 20px;
  padding-right: 20px;
}

.browser-upgrade-message__logo {
  margin-bottom: 30px;
}
