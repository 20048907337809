.device-desktop.home {
	#header {
		&.hover,
		&:hover {
			.logo {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

// .fixed-position,
// .section-blog {
// 	.country-dd-mobile svg path{
// 		fill:#000000;
// 	}
// }

#header {
	@extend .flex-end;
	z-index: 999;
	position: relative;
	background-color: #fff;
	min-height: 114px;
	padding: 38px 40px 28px 40px;
	border-bottom: 1px solid #f4f4f4;

	@include media-breakpoint-down(md) {
		border-bottom: none;
	}
	@include media-breakpoint-down(sm) {
		min-height: 81px;
		padding: 0;
	}

	&:before {
		@include media-breakpoint-down(md) {
			background: #f4f4f4;
			display: block !important;
			position: absolute;
			content: '';
			height: 1px;
			bottom: 0;
			right: 20px;
			left: 20px;

			.fixed-position & {
				left: 0;
				right: 0;
			}
		}
	}

	&.forced-white {
		.country-dd-mobile svg path{
			fill:#000000;
		}
		@include media-breakpoint-down(md) {
			background:white !important;
		}
	}

	.country-dd-mobile {
		.text {
			display: none;

			&.selected {
				display: inline-block;

				@media (max-width: 1339px) {
					display: none;
				}
			}
		}
	}

	.country-dd{
		li.selected a{
			border-bottom:3px solid #F42338;
			padding-bottom:5px;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.culture-select-container{
		li{
			list-style: none;
			a{
				color:#48393A;
				text-decoration: none;
			}
		}
		li.selected a{
			border-bottom:3px solid #F42338;
			padding-bottom:3px;
			
		}
	}

	// &:hover{
	// 	.country-dd-mobile svg path{
	// 		fill:#000000;
	// 	}
	// }

	.country-dd-mobile {
		position: absolute;
		top: 33px;
		right: 32px;
		z-index: 1;
		width: 70px;
		text-align: center;

		@include media-breakpoint-down(lg) {
			top: 52px;
			right: 24px;
			width: 40px;
		}
		@include media-breakpoint-down(md) {
			top: 50px;
			right: 73px;
		}
		@include media-breakpoint-down(sm) {
			top: 32.5px;
			right: 61px;
		}

		&:before {
			content: '';
			position: absolute;
			left: -18px;
			top: -3px;
			bottom: 0;
			width: 1px;
			background: rgba(#f4f4f4, .76);

			@include media-breakpoint-down(lg) {
				// left: 0; // with search icon
				left: -10px;
			}
			@include media-breakpoint-down(md) {
				display: none;
			}
		}


		a {
			color: #443738;
			
			&:hover {
				text-decoration: none;
				color: $pink !important;

				.icon-globe {
					color: $pink !important;
				}
			}
		}

		.icon-globe {
			@include animate(color);
			font-size: 18px;
			line-height: 1;
			color: #dd907c;
			margin-bottom: 7px;
			display: block;

			@include media-breakpoint-down(md) {
				color: #443738;
			}
		}

		.text {
			font-size: 11px;
			font-weight: 700;
			line-height: 1.2728;
			text-transform: uppercase;

			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
	}

	#culture-menu{
		position: relative;
		margin-top:60px;
		width:100%;
		margin-right:100px;
		background:white;
		display:none;
		@media ( min-width:1024px ){
			display:none !important;
		}
	}

	// .fixed-position & {
	// 	box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);
	// }

	// &:before {
	// 	@include media-breakpoint-down(md) {
	// 		display: none;
	// 		height: 1px;
	// 		content: '';
	// 		left: 41px;
	// 		bottom: 1px;
	// 		right: 41px;
	// 		position: absolute;
	// 		background: #ecebeb;
	// 	}
	// }

	// .nav-active & {
	// 	&:before {
	// 		@include media-breakpoint-down(md) {
	// 			display: block;
	// 		}
	// 	}
	// }

	// .device-desktop & {
	// 	&.hover,
	// 	&:hover {
	// 		background: $white;
	// 		// box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1);

	// 		.logo {
	// 			img {
	// 				opacity: 0;
	// 				visibility: hidden;

	// 				&.hover {
	// 					opacity: 1;
	// 					visibility: visible;
	// 				}
	// 			}
	// 		}

	// 		.nav-bar {
	// 			.inner {
	// 				> ul {
	// 					> li {
	// 						> a {
	// 							color: $black;
	// 						}
	// 					}
	// 				}
	// 			}
	// 		}

	// 		.country-dd-mobile {
	// 			a {
	// 				color: $black;
	// 			}

	// 			.icon-globe {
	// 				color: #915040;

	// 				@include media-breakpoint-down(md) {
	// 					color: $black;
	// 				}
	// 			}
	// 		}

	// 		.hamburger {
	// 			&-inner {
	// 				background: $black;

	// 				&:after,
	// 				&:before {
	// 					background: $black;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	.fixed-position & {
		.logo {
			.home & {
				img.home-sticky-logo {
					opacity: 1;
					visibility: visible;
				}
			}

			// img {
			// 	opacity: 0;
			// 	visibility: hidden;

			// 	&.hover {
			// 		opacity: 1;
			// 		visibility: visible;
			// 	}
			// }
		}

		.nav-bar {
			.inner {
				> ul {
					> li {
						> a {
							color: $black;
						}
					}
				}
			}
		}

		.hamburger {
			&-inner {
				background: $black;

				&:after,
				&:before {
					background: $black;
				}
			}
		}
	}

	.nav-active & {
		@include media-breakpoint-down(md) {
			background: $white;

			.logo {
				.home & {
					opacity: 1;
					visibility: visible;
				}

				// img {
				// 	opacity: 0;
				// 	visibility: hidden;

				// 	&.hover {
				// 		opacity: 1;
				// 		visibility: visible;
				// 	}
				// }
			}

			.nav-bar {
				.inner {
					> ul {
						> li {
							> a {
								color: $black;
							}
						}
					}
				}
			}

			.hamburger {
				&-inner {
					background: $black;

					&:after,
					&:before {
						background: $black;
					}
				}
			}
		}
	}
}

/* logo styles */
.logo {
	@include animate(opacity visibility);
	width: 172px;
	overflow: hidden;
	position: absolute;
	left: 40px;
	top: 37px;

	// @include media-breakpoint-down(md) {
	// 	top: 33px;
	// }
	@include media-breakpoint-down(sm) {
		width: 148px;
		top: 23px;
		left: 20px;
	}

	// @media (max-width: 450px) {
	// 	left: 20px;
	// }

	.home.nav-active & {
		@include media-breakpoint-down(md) {
			opacity: 1;
			visibility: visible;
		}
	}

	.home & {
		width: 51px;
		height: 52px;

		@include media-breakpoint-down(md) {
			top: 31px;
		}
		@include media-breakpoint-down(sm) {
			top: 24px;
			width: 32px;
			height: 33px;
		}

		img {
			display: none;

			&.home-sticky-logo {
				display: block;
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	a {
		display: block;
		width: 100%;
		height: 100%;
	}

	img {
		@include animate(opacity visibility);

		&.home-sticky-logo {
			display: none;
		}

		&.hover {
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			position: absolute;
		}
	}
}

.nav-bar{
	@include media-breakpoint-down(md) {
		max-height:0;
		transition:all 0.25s linear;
	}

	.nav-active & {
		@include media-breakpoint-down(md) {
			max-height:4000px;
		}

		.inner {
			@include media-breakpoint-down(md) {
				transform:translateY(0);
			}
		}
	}

	.inner {
		@include media-breakpoint-down(md) {
			transition:all 0.25s linear;
			transform:translateY(-100%);
		}
	}
}

/* navigation bar styles */
.nav-bar {
	width: calc(100% - 210px);
	font-weight: 700;
	padding-top: 12px;
	// padding-right: 152px; // with search icon
	padding-right: 120px;
	position: relative;

	@include media-breakpoint-down(lg) {
		// padding-right: 80px; // with search icon
		padding-right: 60px;
	}

	@include media-breakpoint-down(md) {
		width: 100%;
		padding-right: 0;
	}

	.home & {
		width: calc(100% - 55px);

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	.resize-active & {
		transition: none;
	}

	@include media-breakpoint-down(md) {
		overflow: hidden;
		position:fixed;
		top: 113px;
		left:0;
		right:0;
		z-index:999;
		padding: 0;
	}
	@include media-breakpoint-down(sm) {
		top: 80px;
	}

	.inner {
		@include media-breakpoint-down(md) {
			background: $white;
			padding: 0 41px;
			overflow-y: auto;
			overflow-x: hidden;
			height: calc(100vh - 120px);
		}
		@include media-breakpoint-down(sm) {
			height: calc(100vh - 80px);
		}
		@media (max-width: 450px) {
			padding: 0 20px;
		}
		> ul {
			@extend .flex-end;
			@extend %listreset;

			@include media-breakpoint-down(md) {
				display: block;
				padding-top: 1px;
			}

			.opener {
				display: none;
				position: absolute;
				top: 5px;
				right: 6px;
				font-size: 11px;
				line-height: 1;
				width: 100%;
				padding: 31px 0;

				.icon-plus {
					&:before {
						@include animate(opacity visibility transform);
						display: block;
						right: 0;
						position: absolute;
						top: 37%;
					}
					&:after {
						@include animate(opacity visibility);
						content: "\e907";
						top: 50%;
						right: 0;
						opacity: 0;
						visibility: hidden;
						margin-top: -2px;
						position: absolute;
						transform: translateY(-50%);
						transition-delay: 0.2s;
					}
				}
			}

			> li {
				margin-bottom: 10px;
				position: relative;
				-webkit-font-smoothing: antialiased;

				@include media-breakpoint-down(md) {
					border-bottom: 1px solid #ecebeb;
					margin-bottom: 0;
					line-height: 1.2;
				}

				&.has-drop-down {
					.opener {
						@include media-breakpoint-down(md) {
							display: block;
						}
					}
				}

				&.active {
					> a {
						&:after {
							width: 100%;
						}
					}
					&.active-green {
						> a {
							&:after {
								background: #7ac142;
							}
						}
					}

					&.active-pink {
						> a {
							&:after {
								background: #cd0954;
							}
						}
					}

					&.active-blue {
						> a {
							&:after {
								background: #3fc7f2;
							}
						}
					}
				}

				+ li {
					margin-left: 30px;

					@include media-breakpoint-down(lg) {
						margin-left: 32px;
					}
					@include media-breakpoint-down(md) {
						margin-left: 0;
					}
				}

				&:last-child {
					.nav-bar__drop {
						right: -25px;
						left: auto;

						&.js-slide-hidden {
							@include media-breakpoint-up(md) {
								// right: -25px; // update
								right: -30px;
								left: auto !important;
							}
						}

						li {
							@include media-breakpoint-up(lg) {
								text-align: right;
							}
						}
					}
				}

				&.hover,
				&:hover {
					> a {
						&:after {
							width: 100%;
						}
					}

					.nav-bar__drop {
						opacity: 1;
						visibility: visible;
						transform: translateY(0);
					}
				}

				&.sub-active {
					.opener {
						.icon-plus {
							&:before {
								opacity: 0;
								visibility: hidden;
								transform: rotate(90deg);
							}
							&:after {
								opacity: 1;
								visibility: visible;
								transform: translateY(-50%);
							}
						}
					}
				}

				> a {
					display: block;
					color: #443738;
					text-decoration: none;
					position: relative;
					font-size: rem(16px);

					@include media-breakpoint-down(md) {
						padding: 23px 0 24px;
					}

					&:hover {
						&:after {
							width: 100%;
						}
					}

					&:after {
						@include animate(width);
						height: 3px;
						margin: 0 auto;
						bottom: 2px;
						content: '';
						width: 0;
						left: 0;
						right: 0;
						position: absolute;
						background: #dd907c;

						@include media-breakpoint-down(md) {
							display: none;
						}
					}
				}

				.has-drop-down-a {
					@include media-breakpoint-down(md) {
						padding-right: 35px;
					}
				}
			}
		}
	}


	&__drop {
		@include animate(opacity visibility transform max-height);
		left: -45px;
		top: 100%;
		width: 276px;
		position: absolute;
		padding-top: 36px;
		visibility: hidden;
		opacity: 0;
		// transform: translateY(15px);

		@include media-breakpoint-up(md) {
			&--sm {
				width: 225px;
			}
			&--lg {
				width: 320px;
			}
		}

		@include media-breakpoint-down(md) {
			transform: none;
			width: 100%;
			position: static;
			max-height: 100%;
			padding-top: 0;
			font-size: 16px;
			line-height: 1.2;
			opacity: 1;
			visibility: visible;
		}

		.resize-active & {
			transition: none;
		}

		ul {
			@extend %listreset;
			background: $white;
			font-weight: 400;
			padding: 10px 30px 24px 41px;
			box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);

			@include media-breakpoint-down(md) {
				box-shadow: none;
				padding: 0 20px 2px;
			}
		}

		li {
			margin-bottom: 14px;
			line-height: 1.35;

			@include media-breakpoint-down(md) {
				margin-bottom: 18px;
			}

			br {
				@include media-breakpoint-down(md) {
					display: none;
				}
			}
		}

		a {
			color: $black;
			// text-decoration: none;
			font-size: 16px;

			&:hover {
				color: #915040;
				font-weight: 400;
			}
		}

		&.js-slide-hidden{
			@include media-breakpoint-up(lg) {
				// left: -45px !important; // update
				left: -41px !important;
				top: 100% !important;
				display: auto !important;
			}
		}
	}

	&__more-link {
		color: $black;
		font-size: 14px;
		line-height: 1.6;
		font-weight: 400;
		padding-top: 23px;

		@include media-breakpoint-up(lg) {
			display: none;
		}

		ul {
			@extend %listreset;
		}

		li {
			margin-bottom: 16px;
		}

		a {
			color: $black;
			// text-decoration: none;

			&:hover {
				color: $pink;
			}
		}
	}
}

/* hamburger menu styles */
.hamburger {
	@include animate(transform);
	top: 53px;
	padding: 0;
	right: 40px;
	z-index: 999;
	display: block;
	position: absolute;

	&.forced-dark{
		.hamburger-inner {
			background:$black;
			&:after{
				background:$black;
			}
			&:before{
				background:$black;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
	@include media-breakpoint-down(sm) {
		top: 36px;
		right: 27px;
	}

	// @media (max-width: 450px) {
	// 	right: 27px;
	// }

	.nav-active & {
		transform: translate(3px, -4px);
	}

	&--collapse {
		.hamburger-inner {
			&:after {
				top: -14px;
			}
		}
	}

	&-box {
		display: block;
		width: 20px;
		height: 14px;
	}

	&-inner {
		background: #443738;
		height: 2px;
		width: 20px;

		&:after,
		&:before {
			width: 20px;
			height: 2px;
			border-radius: 0;
			background: #443738;
		}

		&:after {
			top: -14px;
		}

		&:before {
			top: -7px;
		}
	}
}

.country-modal {
	.modal-header {
		margin-bottom: 17px;
	}

	.modal-title {
		font-size: 36px;
		font-weight: 400;
		border-bottom: 1px solid #685f5f;
		padding-bottom: 19px;
		padding-left: 46px;
		position: relative;

		@include media-breakpoint-down(sm) {
			font-size: 22px;
			padding-left: 40px;
		}

		.icon-globe {
			position: absolute;
			top: 3px;
			left: 0;
			font-size: 31px;
			line-height: 1;
			margin-right: 3px;

			@include media-breakpoint-down(sm) {
				font-size: 26px;
				top: 0;
			}
		}
	}

	ul {
		@extend %listreset;
		font-size: 16px;
		padding-left: 4px;

		li {
			margin-bottom: 6px;
		}

		a {
			text-decoration: none;
		}
	}
}

.search-open {
	@include animate(opacity);
	position: absolute;
	top: 45px;
	right: 142px;
	z-index: 99;

	@include media-breakpoint-down(lg) {
		top: 47px;
		right: 78px;
	}

	@include media-breakpoint-down(md) {
		display: none;
	}

	&:hover {
		opacity: .7;
	}
}

.modal {
	.form-inline {
		position: relative;
		padding-right: 130px;

		.form-control {
			width: 100%;
			height: 55px;
			color: $black;
			font-size: 20px;
			line-height: 1.2;
			border-radius: 30px;
			border: none;
			padding: 10px 20px;
			box-sizing: border-box;

			@include placeholder {
				color: $black;
			}
		}

		.btn {
			position: absolute;
			padding: 16px 10px 14px;
			width: 120px;
			font-size: 18px;
			line-height: 1.2;
			text-transform: uppercase;
			top: 0;
			right: 0;
		}
	}
}